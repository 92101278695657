<template>
  <div
    ref="nodeElement"
    class="node"
    :class="isCurrentNodeSelected ? 'selected' : ''"
  >
    <NodeOptions
      v-if="showNodeOptions"
      :node-id="nodeId"
    />
    <div
      class="node-body"
      :class="shape"
      @click.prevent.stop="handleClick()"
    >
      <b-button
        v-if="moduleGraphStore.connectionMode && moduleGraphStore.newConnection.sourceNode && moduleGraphStore.newConnection.sourceNode.nodeId === nodeId"
        size="sm"
        variant="danger"
        class="cancel-connection-btn"
        @click="cancelAddingConnection()"
      >
        Cancel
      </b-button>
      <div class="node-content">
        <div
          v-if="icon || $slots.icon"
          class="mr-3"
        >
          <slot name="icon">
            <b-icon
              :type="iconType"
              class="node-icon"
              :icon="icon"
            />
          </slot>
        </div>
        <div class="overflow-hidden">
          <div
            v-if="showNodeType"
            class="node-type truncate"
          >
            {{ currentNodeType }}
          </div>
          <div
            v-if="showNodeName && currentNode.data && currentNode.data.name"
            class="node-name truncate"
          >
            {{ currentNode.data.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showFooter"
      class="node-footer"
    >
      <NodeList :node-id="nodeId" />
    </div>
  </div>
</template>

<script >
// libs
import { computed, defineAsyncComponent } from '@vue/composition-api';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// others
import { toUpperCaseFirstLetter } from '@/helpers/util';
import { addConnection } from '@/modules/core/components/generics/base-tree-flow/treeFlowUtils';

//-- child components --//
const NodeList = defineAsyncComponent(() => import('@/modules/builder/components/module-graph/nodes/NodeList'));
const NodeOptions = defineAsyncComponent(() => import('./NodeOptions.vue'));

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  icon: {
    type: String,
    default: ''
  },
  iconType: {
    type: String,
    default: 'is-white'
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  shape: {
    type: String,
    default: ''
  },
  nodeId: {
    type: String,
    required: true
  },
  showNodeType: {
    type: Boolean,
    default: true
  },
  showNodeName: {
    type: Boolean,
    default: true
  },
  showNodeOptions: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- composable hooks --//
  const moduleGraphStore = useModuleGraphStore();

  //-- current node logic --//
  const currentNode = computed(() => moduleGraphStore.getNodeById(props.nodeId) || {});
  const currentNodeType = computed(() => toUpperCaseFirstLetter(currentNode.value?.type || ''));
  const isCurrentNodeSelected = computed(() => currentNode.value?.nodeId ? currentNode.value.nodeId === moduleGraphStore.selectedNodeId : false);

  //-- connection logic --//
  const cancelAddingConnection = () => {
    moduleGraphStore.connectionMode = false;
    moduleGraphStore.newConnection.sourceNode = null;
    moduleGraphStore.newConnection.targetNode = null;
  };
  const handleClick = () => {
    if (moduleGraphStore.connectionMode) {
      if (moduleGraphStore.newConnection.sourceNode?.nodeId !== props.nodeId) {
        moduleGraphStore.newConnection.targetNode = currentNode.value;
        const sourceNode = moduleGraphStore.newConnection.sourceNode;
        const conditionLabel = moduleGraphStore.doesAnyConnectionHaveCondition(currentNode.value.connections) ? 'else' : 'always';
        const condition = {
          ...moduleGraphStore.defaultCondition,
          label: conditionLabel
        };
        addConnection(sourceNode, currentNode.value, condition);
      }
      moduleGraphStore.connectionMode = false;
    } else {
      moduleGraphStore.select('node', props.nodeId);
    }
  };
  return {
    moduleGraphStore,
    currentNode,
    currentNodeType,
    isCurrentNodeSelected,
    cancelAddingConnection,
    handleClick
  };
};
__sfc_main.components = Object.assign({
  NodeOptions,
  NodeList
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

// mixins
@mixin clip-node-shape ($clip, $padding-left: 2rem, $padding-right: 2rem) {
  background: transparent;
  box-shadow: none;
  @include node-filter();
  .node-content {
    clip-path: $clip;
    padding-left: $padding-left;
    padding-right: $padding-right;
    background: white;
  }  
}
@mixin node-filter ($is-selected: false) {
  $base-filter: url('~@/assets/rounded-corner-filter.svg#roundedCorners') drop-shadow(0 0.125rem 0.25rem rgba(black, 0.1));
  @if $is-selected {
    filter: $base-filter drop-shadow(0 0 1px $green) !important;
  } @else {
     filter: $base-filter;
  }
}
.node {
  position: relative;
  width: 220px;
  margin-bottom: 10px;

  &.selected {
    .node-body {
      @include node-filter(true);
    }
  }
  .node-body {
    border-radius: 0.5rem;
    background: white;
    box-shadow: 0 0.125rem 0.25rem rgba(black, 0.1);
    .cancel-connection-btn {
      position: absolute;
      left: 60%;
      bottom: -1.875rem;
      z-index: 5;
    }
    .node-content {
      padding-top: 0.625rem; 
      padding-right: 0.875rem; 
      padding-bottom: 0.875rem; 
      padding-left: 0.875rem; 
      display: flex;
      align-items: center;
    }
    .node-icon {
      height: 2rem;
      width: 2rem;
      border-radius: 0.5rem;
    }
    .node-type {
      color: $grey-6;
    }
    .node-type, .node-name {
      font-size: 0.75rem;
      font-family: 'Roboto', sans-serif;
      line-height: 1rem;
    }
    // node shapes
    &.trapezoid {
      @include clip-node-shape(polygon(0 0, 100% 0, 90% 100%, 10% 100%));
    }
    &.capsule {
      border-radius: 1.875rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }
    &.right-pointer {
      @include clip-node-shape(polygon(0% 0%, 88% 0%, 100% 50%, 88% 100%, 0% 100%), 1.25rem, 1.25rem);
    }
  }

  .node-footer {
    display: flex;
    justify-content: center;
    height: 0.625rem;
  }
}
</style>
