<template>
  <editor
    :formula="false"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : null"
    disabled="disabled"
  />
</template>

<script >
import Editor from '@/modules/core/components/wysiwyg/Editor';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
