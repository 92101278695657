<template>
  <div>
    <BaseSelect
      v-model="localDatabaseInfo.table_join_meta.join_type"
      label="Join Type"
    >
      <option
        v-for="(type, index) in options.joinTypes"
        :key="index"
      >
        {{ type }}
      </option>
    </BaseSelect>
    <div v-if="localDatabaseInfo.table_join_meta.join_type">
      <h3 class="mb-2">
        <strong>Join Mapping:</strong>
      </h3>
      <div
        v-for="(table, tIndex) in localDatabaseInfo.table_join_meta.tables"
        :key="tIndex"
        class="columns is-align-items-center"
      >
        <div class="column is-5">
          <BaseSelect
            v-model="table.table_id"
            label="Name"
            placeholder="None"
          >
            <option
              v-for="(tableOption, tOptIndex) in options.tables"
              :key="tOptIndex"
              :value="tableOption.TABLE_NAME"
            >
              {{ tableOption.TABLE_LABEL }}
            </option>
          </BaseSelect>
        </div>
        <div class="column is-5">
          <BaseSelect
            v-model="table.join_on"
            label="Key"
            placeholder="None"
          >
            <option
              v-for="(columnOption, cIndex) in getTableColumns(table.table_id)"
              :key="cIndex"
              :value="columnOption.column_name"
            >
              {{ columnOption.column_label }}
            </option>
          </BaseSelect>
        </div>
        <div class="column is-2 mt-4">
          <b-button
            size="is-small"
            type="is-danger"
            icon-right="delete"
            @click="removeJoinMapping(tIndex)"
          />
        </div>
      </div>
      <b-button
        expanded
        type="is-primary"
        class="mb-3"
        @click="addJoinMapping"
      >
        Add
      </b-button>
    </div>
  </div>
</template>
  
<script >
// lib
import { debouncedWatch } from '@vueuse/core';
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
// composables
import { useLocalDatabase } from './localDatabase';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    localDatabaseInfo,
    options
  } = useLocalDatabase();
  const getTableColumns = tableName => {
    return options.value.columns[tableName]?.columns || [];
  };
  const addJoinMapping = () => {
    // re-assign the position to avoid duplicate position
    localDatabaseInfo.value.table_join_meta.tables = localDatabaseInfo.value.table_join_meta.tables.map((elm, idx) => {
      elm.position = idx;
      return elm;
    });
    localDatabaseInfo.value.table_join_meta.tables.push({
      table_id: '',
      join_on: '',
      position: localDatabaseInfo.value.table_join_meta.tables.length
    });
  };
  const removeJoinMapping = index => {
    localDatabaseInfo.value.table_join_meta.tables.splice(index, 1);
  };
  debouncedWatch(() => localDatabaseInfo.value.table_join_meta.tables, () => {
    emit('mapping-update');
  }, {
    deep: true,
    debounce: 500
  });
  return {
    localDatabaseInfo,
    options,
    getTableColumns,
    addJoinMapping,
    removeJoinMapping
  };
};
__sfc_main.components = Object.assign({
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>
