<template>
  <div>
    <b-tooltip
      :label="condition.label"
      type="is-primary"
      append-to-body
      class="w-full"
    >
      <div class="connection-label truncate has-light-bg">
        {{ condition.label }}
      </div>
    </b-tooltip>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  condition: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
.connection-label {
  font-size: 11px;
}
</style>
