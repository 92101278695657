import { computed, ref } from '@vue/composition-api';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { toUpperCaseFirstLetter } from '@/helpers/util';
import { reactiveResetter } from '@/hooks/utils';

const [ nodesListFilters, resetListFilters ]= reactiveResetter({
    type: '',
    subType: '',
    name: ''
});

export const useModuleNodesList = () => {
    const moduleGraphStore = useModuleGraphStore();

    const nodesList = computed(() => {
        if (moduleGraphStore.nodes && moduleGraphStore.nodes.length) {
            let nodes = moduleGraphStore.nodes.map(node => ({
                nodeId: node.nodeId,
                type: toUpperCaseFirstLetter(node.type),
                subType: node.data.type || '-',
                name: node.data.name,
                connections: moduleGraphStore.connections.filter(connection => connection.targetNodeId === node.nodeId)
            }));
            const currentFilters = Object.entries(nodesListFilters)
                .filter(([filterKey, filterValue]) => !!filterValue);

            currentFilters.forEach(([filterKey, filterValue]) => {
                nodes = nodes.filter((node) => node[filterKey].toLowerCase().includes(filterValue.toLowerCase()));
            });

            return nodes;
        }
        return [];
    });

    return {
        nodesList,
        nodesListFilters,
        resetListFilters
    };
};
