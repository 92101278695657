// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { BUILD_SERVICE_ENDPOINT, THEME_RESOURCE_PATH } from './buildServiceConstants';

const themeServiceConfig = createHttpServiceConfig(BUILD_SERVICE_ENDPOINT, THEME_RESOURCE_PATH);

//-- request interceptors --//
themeServiceConfig.addRequestInterceptor(authInterceptor);

export const fetchThemeService = () => themeServiceConfig.http({
    method: 'GET'
});
