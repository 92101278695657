<template>
  <div>
    <div
      class="mt-2"
    >
      <b-switch
        v-model="validationProperties.hasRestriction"
      >
        Date range restriction
      </b-switch>
    </div>
    <div
      v-if="validationProperties.hasRestriction"
      class="mt-2"
    >
      <RestrictionDatePicker 
        label="From"
        restricted-value-key="restrictedFrom"
        dynamic-value-toggle-key="isRestrictedFromDynamic"
      />
      <RestrictionDatePicker 
        label="To"
        restricted-value-key="restrictedTo"
        dynamic-value-toggle-key="isRestrictedToDynamic"
      />
    </div>
  </div>
</template>

<script >
import { useValidationProperties } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationProperties';
import RestrictionDatePicker from './RestrictionDatePicker';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);
  return {
    validationProperties
  };
};
__sfc_main.components = Object.assign({
  RestrictionDatePicker
}, __sfc_main.components);
export default __sfc_main;
</script>
