<template>
  <b-field
    v-if="field.properties"
    class="column field"
    :class="[
      field.properties.basic.width,
      selectedField && field.id === selectedField.id ? 'selected' : ''
    ]"
  >
    <template
      v-if="field.properties.basic.hasLabel"
      #label
    >
      {{ field.properties.basic.label }} <span
        v-if="field.properties.validation.isRequired"
      >*</span>
      <b-tooltip
        v-if="field.properties.basic.hasInfoTooltip"
        type="is-dark"
        position="is-right"
        size="is-large"
        multilined
      >
        <b-icon icon="help-circle-outline" />
        <template #content>
          {{ field.properties.basic.infoTooltipText }}
        </template>
      </b-tooltip>
    </template>
    <FieldRenderer 
      :field="field"
    />
    <b-button
      v-if="field.type === 'next-btn'"
      class="is-black is-dark"
      :expanded="field.properties.basic.isBlock"
    >
      {{ field.properties.basic.btnText }}
    </b-button>
    <b-button
      v-if="field.type === 'back-btn'"
      class="is-dark is-outlined"
      is-light
      :expanded="field.properties.basic.isBlock"
    >
      {{ field.properties.basic.btnText }}
    </b-button>
  </b-field>
</template>

<script >
import FieldRenderer from '@/modules/builder/components/form-builder/drop-zone/field-components/FieldRenderer.vue';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- composables --//
  const {
    selectedField
  } = useFormBuilder();
  return {
    selectedField
  };
};
__sfc_main.components = Object.assign({
  FieldRenderer
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.field {
  &.selected {
    border: 1px solid $grey-2; 
    background: $grey-7;
  }
}
</style>