<template>
  <div
    v-if="conditions.length"
    class="mt-4"
  >
    <vue-query-builder
      v-model="localDatabaseInfo.query"
      :rules="conditions"
    >
      <template #default="slotProps">
        <query-builder-group
          v-bind="slotProps"
          :query.sync="localDatabaseInfo.query"
        />
      </template>
    </vue-query-builder>
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
import VueQueryBuilder from 'vue-query-builder';
import QueryBuilderGroup from '@/modules/core/components/query-builder/NuclicoreQueryBuilderGroup';
import { useLocalDatabase } from './localDatabase';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    localDatabaseInfo,
    selectedTableColumns,
    joinedTableColumns
  } = useLocalDatabase();
  const conditions = computed(() => {
    const columns = (localDatabaseInfo.value.action === 'Join' ? joinedTableColumns.value : selectedTableColumns.value) || [];
    return columns.map(column => {
      const label = localDatabaseInfo.value.action === 'Join' ? `${column.table_label} > ${column.column_label}` : column.column_label;
      const label_ref = localDatabaseInfo.value.action === 'Join' ? `${column.table_name} > ${column.column_name}` : column.column_name;
      return {
        type: 'text',
        id: label_ref,
        label,
        operators: ['equals', 'does not equal', 'less than', 'more than', 'empty', 'less than or equal to', 'more than or equal to', 'not empty', 'contains', 'does not contain', 'begins with', 'ends with', 'is part of', 'is not part of']
      };
    });
  });
  return {
    localDatabaseInfo,
    conditions
  };
};
__sfc_main.components = Object.assign({
  VueQueryBuilder,
  QueryBuilderGroup
}, __sfc_main.components);
export default __sfc_main;
</script>
