<template>
  <div>
    <strong>Data table</strong>
    <table width="100%">
      <tr style="min-height: 30px!important;">
        <td
          v-for="(n, cIndex) in field.properties.basic.columns"
          :key="cIndex"
          style="border: 1px solid gray"
        >
          &nbsp;
        </td>
      </tr>
    </table>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>
