<template>
  <div>
    <BaseInput
      v-model="fileCreatorInfo.name"
      label="Node name"
    />

    <Editor
      v-model="fileCreatorInfo.fileName"
      :input="true"
      label="File name"
      style="margin-bottom: 20px"
    />

    <BaseSelect
      v-model="fileCreatorInfo.extension"
      label="File extension"
    >
      <option
        v-for="(extension, index) in options.extensions"
        :key="index"
      >
        {{ extension }}
      </option>
    </BaseSelect>

    <div>
      <b-switch
        v-model="fileCreatorInfo.fetchFileFromUrl"
        class="mb-4"
      >
        Fetch file from URL
      </b-switch>
    </div>
    <div
      v-if="fileCreatorInfo.extension === 'PDF'"
      class="my-2"
    >
      <div
        v-if="!fileCreatorInfo.fetchFileFromUrl"
      >
        <b-switch
          v-model="fileCreatorInfo.useTemplate"
          class="mb-4"
        >
          Use template document for content
        </b-switch>
      </div>
      <div v-if="fileCreatorInfo.useTemplate">
        <b-upload
          v-if="fileCreatorInfo.useTemplate && !fileCreatorInfo.fetchFileFromUrl"
          v-model="fileCreatorInfo.templateFile"
          class="file-label"
          accept=".docx"
          required
          validation-message="Please select a docx file"
          @input="parseVariables()"
        >
          <span class="file-cta">
            <b-icon
              class="file-icon"
              icon="upload"
            />
            <span class="file-label">Click to upload</span>
          </span>
          <span
            v-if="fileCreatorInfo.templateFile"
            class="file-name"
          >
            {{ fileCreatorInfo.templateFileName }}
          </span>
        </b-upload>
        <div
          v-if="(fileCreatorInfo.templateFileName && uploadedFileInfo)"
          class="mt-5 mb-5"
        >
          <div class="is-flex is-align-items-center mb-1">
            <BaseLabel class="mb-0 mr-2">
              Uploaded File:
            </BaseLabel>
            <a
              :href="uploadedFileInfo.fileUrl"
            >
              {{ fileCreatorInfo.templateFileName }}
            </a>
          </div>
          <div
            v-if="uploadedFileInfo.uploadTime"
            class="is-flex is-align-items-center"
          >
            <BaseLabel class="mb-0 mr-2">
              Uploaded at:
            </BaseLabel>
            <span>
              {{ uploadedFileInfo.uploadTime }}
            </span>
          </div>
        </div>
        <div
          v-for="(dynamicVar, index) in fileCreatorInfo.dynamicVariables"
          :key="index"
        >
          <Editor
            v-model="dynamicVar.value"
            :input="true"
            :formula="true"
            :label="dynamicVar.label"
          />
        </div>
      </div>
      <div v-else-if="!fileCreatorInfo.fetchFileFromUrl">
        <Editor
          ref="grid"
          v-model="fileCreatorInfo.content"
          :input="false"
          label="File content"
          style="margin-bottom: 20px"
        />
      </div>
    </div>
    <div v-else-if="['CSV', 'TXT'].includes(fileCreatorInfo.extension)">
      <div>
        <b-switch
          v-model="fileCreatorInfo.multiline"
          class="mb-4"
        >
          Data has multiple lines
        </b-switch>
      </div>

      <div v-if="fileCreatorInfo.multiline">
        <b-switch
          v-model="fileCreatorInfo.labelsInFirstLine"
          class="mb-4"
        >
          Are the labels presented in the first line?
        </b-switch>
      </div>
      <Editor
        v-if="!fileCreatorInfo.fetchFileFromUrl"
        ref="grid"
        v-model="fileCreatorInfo.content"
        :input="false"
        label="File content"
        style="margin-bottom: 20px"
      />
    </div>
    <div v-if="fileCreatorInfo.fetchFileFromUrl">
      <Editor
        v-model="fileCreatorInfo.fileUrl"
        :input="true"
        :formula="true"
        label="File URL"
      />
    </div>
  </div>
</template>

<script >
// libs
import { computed, ref, watch } from '@vue/composition-api';
import axios from 'axios';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
// composables
import { convertUTCToLocalTime } from '@/helpers/util';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const defaultValue = {
    name: 'FileCreator',
    application_id: null,
    multiline: false,
    labelsInFirstLine: false,
    module_id: null,
    fileName: '',
    extension: '',
    content: '',
    useTemplate: true,
    templateFile: null,
    dynamicVariables: [],
    templateFileName: '',
    fileUploadTime: '',
    fetchFileFromUrl: false,
    fileUrl: ''
  };
  const fileCreatorInfo = ref({});
  const options = ref({
    extensions: ['TXT', 'CSV', 'PDF', 'ZIP']
  });
  const loadInfo = () => {
    fileCreatorInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => fileCreatorInfo.value, () => {
    emit('input', fileCreatorInfo.value);
  }, {
    deep: true
  });
  const uploadedFileInfo = computed(() => {
    if (fileCreatorInfo.value.templateFileName) {
      return {
        fileUrl: `${window.env.VUE_APP_APP_SERVICE_DOMAIN}/${fileCreatorInfo.value.templateFileName}`,
        uploadTime: fileCreatorInfo.value.fileUploadTime ? convertUTCToLocalTime(fileCreatorInfo.value.fileUploadTime, 'DD.MM.YYYY HH:mm', 'ddd, DD MMM YYYY HH:mm:ss [GMT]') : ''
      };
    }
    return null;
  });
  watch(() => fileCreatorInfo.value.fetchFileFromUrl, () => {
    if (fileCreatorInfo.value.fetchFileFromUrl) {
      fileCreatorInfo.value.useTemplate = false;
      fileCreatorInfo.value.dynamicVariables = [];
      fileCreatorInfo.value.templateFile = null;
    } else {
      fileCreatorInfo.value.fileUrl = '';
    }
  });
  const parseVariables = async () => {
    try {
      const formData = new FormData();
      formData.append('file', fileCreatorInfo.value.templateFile);
      const {
        data: {
          data
        }
      } = await axios.post(window.env.VUE_APP_APP_SERVICE_DOMAIN + '/compiler/doc-variable', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const newVariables = data.variables.map(variable => ({
        label: variable,
        value: ''
      }));
      if (fileCreatorInfo.value.dynamicVariables.length) {
        const common = fileCreatorInfo.value.dynamicVariables.filter(o => newVariables.some(({
          label
        }) => o.label === label));
        const diff = newVariables.filter(o => !fileCreatorInfo.value.dynamicVariables.some(({
          label
        }) => o.label === label));
        fileCreatorInfo.value.dynamicVariables = common.concat(diff);
      } else {
        fileCreatorInfo.value.dynamicVariables = [...newVariables];
      }
      fileCreatorInfo.value.templateFileName = data.file_name;
      fileCreatorInfo.value.fileUploadTime = new Date().toUTCString();
    } catch (err) {
      console.error(err);
    }
  };
  return {
    fileCreatorInfo,
    options,
    uploadedFileInfo,
    parseVariables
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  Editor,
  BaseSelect,
  BaseLabel
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>
@import '~handsontable/dist/handsontable.full.css';

</style>