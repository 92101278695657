<template>
  <div>
    <b-checkbox
      class="column is-12"
      size="is-small"
      native-value="default"
    >
      <p v-html="field.properties.basic.rawText" />
    </b-checkbox>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>
