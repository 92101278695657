<template>
  <div>
    <div
      class="mt-2"
    >
      <b-switch
        v-model="validationProperties.hasRegex"
      >
        Use regex for validation
      </b-switch>
    </div>


    <div
      v-if="validationProperties.hasRegex"
      class="mt-2"
    >
      <BaseInput
        v-model="validationProperties.regex"
        label="Regex"
        expanded
      />

      <div
        v-if="validationProperties.regex"
        class="mt-2"
      > 
        <DynamicValueSwitch
          v-model="validationProperties.hasRegexMessage"
          :is-dynamic="validationProperties.isRegexMessageDynamic"
          label="Regex Error Message"
          class="mt-1 mb-2"
          custom-switch-classes="is-flex-direction-column-reverse"
          dynamic-switch-classes="ml-auto"
          @update-dynamic-switch="toggleDynamicSwitch"
        >
          <BaseInput
            v-model="validationProperties.hasRegexMessage"
            label="Regex Error Message"
            size="is-small"
            expanded
          />
        </DynamicValueSwitch>
      </div>
    </div>
  </div>
</template>

<script >
import { set } from '@vue/composition-api';
import { useValidationProperties } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationProperties';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);
  const toggleDynamicSwitch = isDynamic => {
    set(validationProperties.value, 'hasRegexMessage', '');
    set(validationProperties.value, 'isRegexMessageDynamic', isDynamic);
  };
  return {
    validationProperties,
    toggleDynamicSwitch
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  DynamicValueSwitch
}, __sfc_main.components);
export default __sfc_main;
</script>
