<template>
  <BaseInput
    :mask="{
      ...(field.properties.basic.currencySymbol || {}),
      ...(field.properties.basic.format || {})
    }"
    size="is-small"
    :type="field.properties.basic.currencySymbol || field.properties.basic.format ? 'text' : 'number'"
    :disabled="field.properties.basic.isDisabled"
    :controls="field.properties.basic.showControls"
    :placeholder="field.properties.basic.hasPlaceholder || ''"
    :value="field.properties.basic.hasDefaultValue || ''"
  />
</template>

<script >
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
