import { createHttpServiceConfig, getAuthHeader } from '@/services/httpFactories';
import { INTEGRATION_SERVICE_ENDPOINT, INTEGRATION_VARIABLES_RESOURCE_PATH } from './integrationServiceConstants';

const interpreterModelServiceConfig = {
    ...createHttpServiceConfig(INTEGRATION_SERVICE_ENDPOINT, INTEGRATION_VARIABLES_RESOURCE_PATH),
    /**
     * @param {string} channelId
     * @param {string} productId
     */
    getIntegrationCheckResourcePath: (channelId, productId) => `/${channelId}/${productId}`,
};

/**
 * @param {string} channelId
 * @param {string} productId
 */
export const variablesIntegrationService = (channelId, productId) => interpreterModelServiceConfig.http({
    method: 'GET',
    url: interpreterModelServiceConfig.getIntegrationCheckResourcePath(channelId, productId)
});
