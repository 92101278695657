import { ref } from '@vue/composition-api';

const dataMapperInfo = ref({});

const shouldAvoidVariablesUpdate = ref(false);

export const useDataMapper = () => {
    return {
        dataMapperInfo,
        shouldAvoidVariablesUpdate
    };
};
