<template>
  <div>
    <BaseSelect
      v-model="sidebarType"
      label="Integration type"
    >
      <option
        v-for="(integration, index) in integrations"
        :key="index"
      >
        {{ integration }}
      </option>
    </BaseSelect>

    <SidebarLoader v-if="isSidebarLoading" />

    <template v-else>
      <SalesChannel
        v-if="sidebarType === 'Sales Channel'"
        v-model="sidebarInfo"
      />
    </template>
  </div>
</template>

<script >
// libs
import { computed, ref } from '@vue/composition-api';
// components
import SalesChannel from '@/modules/builder/components/module-sidebar/integration/SalesChannel';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import SidebarLoader from '@/modules/builder/components/module-sidebar/SidebarLoader.vue';
// composables
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const {
    isSidebarLoading,
    sidebarType
  } = useModuleSidebar();
  const integrations = ref(['Sales Channel']);
  const sidebarInfo = computed({
    get() {
      return props.value;
    },
    set(newValue) {
      const payload = {
        ...newValue,
        type: sidebarType.value
      };
      emit('input', payload);
    }
  });
  return {
    isSidebarLoading,
    sidebarType,
    integrations,
    sidebarInfo
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  SidebarLoader,
  SalesChannel
}, __sfc_main.components);
export default __sfc_main;
</script>
