<template>
  <div>
    <BaseSelect 
      v-model="basicProperties.serviceProvider"
      label="Service Provider"
      class="mb-2"
    >
      <option
        v-for="(serviceProvider, envIndex) in serviceProviders"
        :key="'serviceProvider' + envIndex"
        :value="serviceProvider.value"
      >
        {{ serviceProvider.label }}
      </option>
    </BaseSelect>
    <template v-if="selectedServiceProvider">
      <BaseLabel class="mb-2">
        {{ selectedServiceProvider.label }} Gateway Properties:
      </BaseLabel>
      <component
        :is="selectedServiceProvider.component"
      />
    </template>
  </div>
</template>

<script >
// libs
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
// composables
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';

//-- payment gateway provider properties --//
const ElementGatewayProperties = defineAsyncComponent(() => import('./ElementGatewayProperties.vue'));
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const serviceProviders = ref([{
    label: 'Element',
    value: 'Element',
    component: ElementGatewayProperties
  }]);
  const selectedServiceProvider = computed(() => basicProperties.value.serviceProvider ? serviceProviders.value.find(provider => provider.label === basicProperties.value.serviceProvider) : null);
  return {
    basicProperties,
    serviceProviders,
    selectedServiceProvider
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  BaseLabel
}, __sfc_main.components);
export default __sfc_main;
</script>
