<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="vqb-rule mt-2">
    <!-- Label/Delete Button -->
    <div class="is-flex is-justify-content-space-between nav-del-row">
      <div>
        <p>{{ rule ? rule.label : '' }}</p>
      </div>
      <div>
        <b-button @click="remove">
          <b-icon
            icon="delete-outline"
            type="is-danger"
          />
        </b-button>
      </div>
    </div>

    <div class="nav-column-row">
      <!-- Dropdown -->
      <div class="column is-6">
        <BaseSelect
          v-if="rule && rule.operands !== undefined"
          v-model="query.operand"
          expanded
        >
          <option
            v-for="operand in rule.operands"
            :key="operand"
            :value="operand"
          >
            {{
              operand
            }}
          </option>
        </BaseSelect>

        <BaseSelect
          v-if="rule && rule.operators !== undefined && rule.operators.length > 1"
          v-model="query.operator"
          expanded
        >
          <option
            v-for="operator in rule.operators"
            :key="operator"
            :value="operator"
          >
            {{ operator }}
          </option>
        </BaseSelect>
      </div>

      <!-- Editor -->
      <div
        v-if="rule"
        class="column is-6"
      >
        <Editor
          v-if="rule.inputType === 'text' && ( query.operator !== 'empty' && query.operator !== 'not empty')"
          v-model="query.value"
          :formula="true"
          :input="true"
        />

        <template v-if="rule.inputType === 'radio'">
          <BaseSelect
            v-model="query.value"
            expanded
          >
            <option
              v-for="choice in rule.choices"
              :key="choice"
              :value="choice.value"
            >
              {{
                choice.label
              }}
            </option>
          </BaseSelect>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderRule from 'vue-query-builder/dist/rule/QueryBuilderRule.umd.js';
import Editor from './../wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';

export default {
    components: {
        Editor,
        BaseSelect
    },
    extends: QueryBuilderRule
};
</script>

<style>
.vqb-rule {
  border-bottom: 2px solid #EBEBEF;
	padding: 0.5rem 0;
}

.vqb-rule .nav-del-row {
	align-items: center;
  padding: 0 1.5rem 0 0.688rem;
}

.vqb-rule .nav-del-row p {
  font-family: 'Roboto', sans-serif;
	font-size: 12px;
	line-height: 14px;
	color: #222222;
}

.vqb-rule .nav-del-row button {	
  width: 30px;
  height: 30px;
  background: rgba(251, 131, 117, 0.2);
  border-radius: 8px;
  border-color: transparent;
  visibility: hidden;
}

/* Hover */
.vqb-rule:hover .nav-del-row button {
  visibility: visible;
}

.vqb-rule .nav-column-row {
  display: flex;
}

.vqb-rule .nav-column-row .column {
  margin-left: -5px;
  margin-right: -5px;
}

</style>
