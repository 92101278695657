<template>
  <b-dropdown
    aria-role="list"
    position="is-bottom-left"
    append-to-body
    @active-change="updateDropdownToggle"
  >
    <template #trigger>
      <b-icon
        icon="dots-horizontal"
        class="options-icon"
        :class="{
          active: isDropdownActive
        }"
      />
    </template>
    <b-dropdown-item
      aria-role="listitem"
      @click="notifyEdit()"
    >
      <b-icon
        size="is-small"
        class="mr-1"
        icon="pencil"
      />
      <span class="ml-2">Edit</span>
    </b-dropdown-item>
    <b-dropdown-item
      aria-role="listitem"
      custom
      class="is-flex is-align-items-center is-justify-content-center"
    >
      <b-button
        icon-left="delete-outline"
        size="is-small"
        type="is-danger is-light"
        class="rounded-8 w-full"
        @click.stop="notifyDelete()"
      >
        Delete
      </b-button>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script >
import { ref } from '@vue/composition-api';
import { variablesBus, ON_VARIABLE_EDIT, ON_VARIABLE_DELETE } from './variablesBus';
const __sfc_main = {};
__sfc_main.props = {
  node: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- dropdown logic --//
  const isDropdownActive = ref(false);
  const updateDropdownToggle = toggleState => {
    isDropdownActive.value = toggleState;
  };
  const notifyEdit = () => {
    variablesBus.publish(ON_VARIABLE_EDIT, props.node);
  };
  const notifyDelete = () => {
    variablesBus.publish(ON_VARIABLE_DELETE, props.node);
  };
  return {
    isDropdownActive,
    updateDropdownToggle,
    notifyEdit,
    notifyDelete
  };
};
export default __sfc_main;
</script>
