<template>
  <JsonBuilder
    v-model="builderNodes"
    class="mt-4"
    :node-name="nodeName"
  />
</template>

<script >
import { computed } from '@vue/composition-api';
import JsonBuilder from '@/modules/core/components/json-builder/JsonBuilder.vue';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Array,
    required: true
  },
  nodeName: {
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const builderNodes = computed({
    get() {
      return props.value;
    },
    set(value) {
      emit('input', value);
    }
  });
  return {
    builderNodes
  };
};
__sfc_main.components = Object.assign({
  JsonBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>
