<template>
  <div v-if="selectedField && selectedField.type">
    <BaseHeading
      tag="h6"
      class="has-text-weight-bold"
    >
      Event Mapping:
    </BaseHeading>
    <div
      v-for="(eventMapping, emIndex) in fieldEventMappings"
      :key="'event-mapping' + emIndex"
    >
      <b-collapse
        class="panel mb-3"
        animation="slide"
        :aria-id="'contentIdForA11y' + emIndex"
      >
        <template #trigger>
          <div
            class="panel-heading"
            role="button"
            :aria-controls="'contentIdForA11y' + emIndex"
          >
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <div>
                {{ getEventLabel(eventMapping.event) || ('Event' + ' ' + (emIndex + 1)) }}
              </div>
              <b-icon
                size="is-small"
                icon="trash-can-outline"
                type="is-danger"
                class="remove-event-button"
                @click.native.stop="removeEvent(emIndex)"
              />
            </div>
          </div>
        </template>
        <div class="panel-body p-3">
          <BaseSelect
            v-model="eventMapping.event"
            label="Event"
            expanded
          >
            <option
              v-for="(fieldEvent, feIndex) in fieldEventOptions"
              :key="'field-event' + feIndex"
              :value="fieldEvent.value"
            >
              {{ fieldEvent.label }}
            </option>
          </BaseSelect>
          <BaseSelect
            v-model="eventMapping.workflow"
            label="Workflow"
            :loading="areWorkflowsLoading"
            expanded
          >
            <option
              v-for="(workflow, wIndex) in workflows"
              :key="'workflow' + wIndex"
              :value="workflow.id"
            >
              {{ workflow.name }}
            </option>
          </BaseSelect>
          <Editor
            v-model="eventMapping.params"
            label="Parameters (optional)"
            :input="true"
            class="mb-2"
          />
        </div>
      </b-collapse>
    </div>
    <b-button
      type="is-primary"
      size="is-small"
      expanded
      class="mt-3"
      @click="addEvent"
    >
      +Add Event
    </b-button>
  </div>
</template>

<script >
// lib
import { onMounted, ref } from '@vue/composition-api';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseHeading from '@/modules/core/components/generics/BaseHeading.vue';
// composables
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useRoute } from '@/hooks/vueRouter';
import { useFieldEvents } from './fieldEvents';
// services
import { fetchWorkflowsService } from '@/services/application-service/workflowRequests';
// misc
import { generateDefaultEventMapping } from '@/modules/builder/components/form-builder/form-elements/elementFactories';

// external composables 
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    selectedField
  } = useFormBuilder();
  const route = useRoute();
  const {
    fieldEventOptions,
    fieldEventMappings,
    getEventLabel
  } = useFieldEvents();
  const addEvent = () => {
    fieldEventMappings.value.push(generateDefaultEventMapping());
  };

  /**
   * @param {number} index 
   */
  const removeEvent = index => {
    fieldEventMappings.value.splice(index, 1);
  };

  // composables specific to this component
  const useWorkflows = () => {
    const areWorkflowsLoading = ref(false);
    const workflows = ref([]);
    const fetchWorkflows = async () => {
      try {
        const {
          appId,
          moduleId
        } = route.params;
        areWorkflowsLoading.value = true;
        const {
          data: {
            data
          }
        } = await fetchWorkflowsService(appId, moduleId);
        workflows.value = data;
      } catch (err) {
        console.error(err);
      } finally {
        areWorkflowsLoading.value = false;
      }
    };
    onMounted(() => {
      fetchWorkflows();
    });
    return {
      areWorkflowsLoading,
      workflows
    };
  };
  const {
    areWorkflowsLoading,
    workflows
  } = useWorkflows();
  return {
    selectedField,
    fieldEventOptions,
    fieldEventMappings,
    getEventLabel,
    addEvent,
    removeEvent,
    areWorkflowsLoading,
    workflows
  };
};
__sfc_main.components = Object.assign({
  BaseHeading,
  BaseSelect,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.remove-event-button {
  border-radius: 50%;
  padding: 1rem;
  background-color: rgba($danger, 0.2);
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: rgba($danger, 0.3);
  }
}
</style>
