<template>
  <div>
    <div class="field has-addons">
      <p class="p-0 control column is-10">
        <b-input
          size="is-small"
          :placeholder="field.properties.basic.labels.street"
        />
      </p>
      <p class="p-0 control column is-2">
        <b-input
          size="is-small"
          :placeholder="field.properties.basic.labels.number"
        />
      </p>
    </div>
    <div class="field has-addons">
      <p
        v-if="field.properties.basic.hasState || field.properties.basic.hasStateDynamic"
        class="p-0 control column is-6"
      >
        <b-input
          size="is-small"
          :placeholder="field.properties.basic.labels.state"
        />
      </p>
      <p
        v-if="field.properties.basic.hasDistrict || field.properties.basic.hasDistrictDynamic"
        class="p-0 control column is-6"
      >
        <b-input
          size="is-small"
          :placeholder="field.properties.basic.labels.district"
        />
      </p>
    </div>
    <div class="field has-addons">
      <p class="control p-0 column is-3">
        <b-input
          size="is-small"
          class="address-zipcode"
          :maxlength="5"
          :placeholder="field.properties.basic.labels.zipCode"
        />
      </p>
      <p class="control column p-0 is-9">
        <b-input
          size="is-small"
          :placeholder="field.properties.basic.labels.city"
        />
      </p>
    </div>
    <div class="field has-addons">
      <p
        v-if="field.properties.basic.hasCountry || field.properties.basic.hasCountryDynamic"
        class="control p-0 column is-12"
      >
        <b-input
          size="is-small"
          :placeholder="field.properties.basic.labels.country"
        />
      </p>
    </div>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>

<style lang="scss">
.address-zipcode {
  .is-invisible {
    display: none !important;
  }
}
</style>
