<template>
  <div>
    <BaseInput
      v-model="fileMoverInfo.name"
      label="Node name"
    />
    
    <BaseInput
      v-model="fileMoverInfo.host"
      label="Host"
    />
  
    <BaseInput
      v-model="fileMoverInfo.user"
      label="User"
    />
  
    <BaseInput
      v-model="fileMoverInfo.password"
      type="password"
      label="Password"
    />
  
    <BaseInput
      v-model="fileMoverInfo.port"
      type="number"
      label="Port"
    />

    <div
      v-if="!fileMoverInfo.moveAllFiles"
      class="mb-2"
    >
      <BaseLabel>
        Source file name
      </BaseLabel>
      <Editor
        v-model="fileMoverInfo.fileName"
        :input="true"
        :formula="true"
      />
    </div>

    <div
      v-if="!fileMoverInfo.moveAllFiles"
      class="mb-2"
    >
      <BaseLabel>
        Destination file fame
      </BaseLabel>
      <Editor
        v-model="fileMoverInfo.destinationFileName"
        :input="true"
        :formula="true"
      />
    </div>

    <BaseInput
      v-model="fileMoverInfo.sourcePath"
      label="Source folder structure"
    />
 
    <BaseInput
      v-model="fileMoverInfo.destinationPath"
      label="Destination folder structure"
    />
  
    <b-switch
      v-model="fileMoverInfo.moveAllFiles"
      class="mb-4"
    >
      Move all files in the folder?
    </b-switch>
  
    <b-button
      expanded
      type="is-primary"
      class="mb-3"
      @click="testConnection"
    >
      Test the connection
    </b-button>
    <b-message
      v-if="connectionOK"
      size="is-small"
      type="is-success"
    >
      Connection successful
    </b-message>
    <b-message
      v-if="!connectionOK && connectionTestSent"
      size="is-small"
      type="is-danger"
    >
      There is a problem with a connection.
    </b-message>
  </div>
</template>
  
<script >
// libs
import { ref, watch } from '@vue/composition-api';
// components
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor';
// services
import { parseModuleService } from '@/services/interpreter-service/interpreterModuleRequests';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const defaultValue = {
    name: 'FileMover',
    application_id: null,
    module_id: null,
    host: '',
    user: '',
    password: '',
    port: '22',
    sourcePath: '/',
    destinationPath: '/',
    moveAllFiles: true,
    fileName: '',
    destinationFileName: ''
  };
  const fileMoverInfo = ref({});
  const loadInfo = () => {
    fileMoverInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => fileMoverInfo.value, () => {
    emit('input', fileMoverInfo.value);
  }, {
    deep: true
  });

  //-- test connection logic --//
  const connectionOK = ref(false);
  const connectionTestSent = ref(false);
  const testConnection = async () => {
    try {
      const {
        appId
      } = route.params;
      const {
        moduleId
      } = moduleStore;
      await parseModuleService(moduleId, {
        remoteOptions: {
          ...fileMoverInfo.value,
          folder: '/'
        },
        application_id: appId,
        node_id: moduleGraphStore.selectedNodeId,
        node_name: fileMoverInfo.value.name
      });
      connectionOK.value = true;
      await moduleStore.fetchVariables(appId);
    } catch (err) {
      console.error(err);
      connectionTestSent.value = true;
    }
  };
  return {
    fileMoverInfo,
    connectionOK,
    connectionTestSent,
    testConnection
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseLabel,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
