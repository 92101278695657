<template>
  <div>
    <small>Node name</small>
    <b-input
      v-model="variableSetterInfo.name"
      size="is-small"
      class="mb-2"
    />
    <small>Variable</small>
    <Editor
      v-model="variableSetterInfo.variableReference"
      :input="true"
      :formula="false"
    />
    <small>Value</small>
    <Editor
      v-model="variableSetterInfo.variableValue"
      :input="true"
      :formula="true"
    />
  </div>
</template>
  
<script >
import { ref, watch } from '@vue/composition-api';
import Editor from '@/modules/core/components/wysiwyg/Editor';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const defaultValue = {
    name: 'VariableSetter',
    application_id: null,
    module_id: null,
    variableReference: '',
    variableValue: ''
  };
  const variableSetterInfo = ref({});
  const loadInfo = () => {
    variableSetterInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => variableSetterInfo.value, () => {
    emit('input', variableSetterInfo.value);
  }, {
    deep: true
  });
  return {
    variableSetterInfo
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
