<template>
  <b-autocomplete
    v-if="field.properties.basic.hasAutocomplete"
    size="is-small"
    expanded
    :data="choices"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    @select="option => (autoCompleteSelected = option)"
  />
  <b-select
    v-else
    size="is-small"
    expanded
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
  >
    <option
      v-for="(choice, cIndex) in choices"
      :key="cIndex"
    >
      {{ choice }}
    </option>
  </b-select>
</template>

<script >
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const choices = computed(() => {
    if (!props.field.properties.basic.choices.length) {
      return [];
    }
    if (props.field.properties.basic.sortOptionsInAlphabeticOrder) {
      return [...props.field.properties.basic.choices].sort();
    } else {
      return props.field.properties.basic.choices;
    }
  });
  return {
    choices
  };
};
export default __sfc_main;
</script>
