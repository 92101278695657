<template>
  <div
    ref="treeElement"
    class="tree-container hide-scrollbar"
    :class="isCentered && !isTreeScrollable ? 'content-center' : ''"
  >
    <div
      class="tree-inner-container"
      :style="{
        transform: areConnectionsUpdating ? 'none' : `scale(${zoom})`
      }"
    >
      <div class="tree">
        <ul class="tree-list">
          <SubTree
            v-for="(treeNode, nIndex) in nestedTree"
            :key="nIndex"
            :node="treeNode"
            :tree-nodes="value"
            :node-components="nodeComponents"
          />
        </ul>
      </div>
      <TreeConnections
        :tree-nodes="value"
        :delete-connection-button="deleteConnectionButton"
        :selected-connection-id="selectedConnectionId"
        :connection-label-component="connectionLabelComponent"
      />
    </div>
  </div>
</template>

<script >
import { onMounted, ref, set, watch } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import { getNodeConnections, deleteConnectionFromTree } from './treeFlowInternal';
import { generateConnection } from './treeFlowUtils';
import TreeConnections from './TreeConnections.vue';
import SubTree from './SubTree.vue';
import { isEqual } from 'lodash';
import { subscription, ON_PARENT_CHILD_CONNECTION_DELETE, ON_DANGLING_NODE_CONNECT, ON_CONNECTIONS_UPDATE, ON_CONNECTION_SELECT } from './treeFlowPubSub';
import { useDragScroll } from '@/hooks/utils';
import { nextFrame, delay } from '@/helpers/util';

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  nodeComponents: {
    type: Array,
    required: true,
    description: 'custom components to be rendered as tree nodes'
  },
  value: {
    type: Array,
    required: true,
    description: 'flat array of nodes which is used to generate nested data structure required for tree generation'
  },
  deleteConnectionButton: {
    type: Object,
    default: null,
    description: 'custom component for delete connection button'
  },
  isCentered: {
    type: Boolean,
    default: true,
    description: 'centers tree horizontally on intial load'
  },
  defaultCondition: {
    type: Object,
    default: () => ({
      label: 'condition'
    }),
    description: 'default value for initializing condition object (this allows user to set condition object as per their requirements, note: provided object should atleast have label property)'
  },
  selectedConnectionId: {
    type: String,
    default: '',
    description: 'Id of selected connection (adds selected css class to connection with specified ID)'
  },
  connectionLabelComponent: {
    type: Object,
    default: null,
    description: 'Custom component for condition label on each connection'
  },
  zoom: {
    type: Number,
    default: 1,
    description: 'Zooms in/out the tree based on value'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- component events --//
  const emit = __ctx.emit;

  //-- scroll tree logic --//
  /** @type {import('@vue/composition-api').Ref<HTMLElement | null>} */
  const treeElement = ref(null);
  const {
    scrollLeft,
    updateScroll
  } = useDragScroll(treeElement, {
    scrollPosition: props.position
  });
  const isTreeScrollable = ref(false);
  const updateTreeScrollable = async () => {
    await nextFrame();
    isTreeScrollable.value = treeElement.value.firstElementChild.clientWidth > treeElement.value.clientWidth;
  };
  watch(() => scrollLeft.value, () => {
    if (scrollLeft.value > 0) {
      updateTreeScrollable();
    }
  });

  //-- default tree position logic --//
  const handleDefaultTreePosition = async () => {
    if (treeElement.value && treeElement.value.firstElementChild) {
      await updateTreeScrollable();
      if (props.isCentered && isTreeScrollable.value) {
        updateScroll({
          horizontalScroll: 'center'
        });
      }
    }
  };
  onMounted(() => {
    handleDefaultTreePosition();
  });

  //-- tree generation logic --//
  /**
   * @type {import('@vue/composition-api').Ref<import('./types/tree').ITreeNode[]>}
   * flat list of tree nodes for comparing with external bounded prop value and 2-way binding
   */
  const flatTreeList = ref([]);
  /**
   * @type {import('@vue/composition-api').Ref<import('./types/tree').ITreeNode[]>}
   * nested tree data structure used by recursive component
   */
  const nestedTree = ref([]);

  /**
   * Converts flat array of nodes to nested tree structure
   * @param {import('./types/tree').ITreeNodeProp[]} treeNodes
   */
  const generateTree = treeNodes => {
    if (!treeNodes || !treeNodes.length) {
      console.error('On Mounted: Expected prop value as an array with atleast 1 element, received: ', treeNodes);
      return;
    }
    const nodesConnections = treeNodes.map(node => node.position.split('/'));
    const nodesConnectionLengths = nodesConnections.map(connection => connection.length);
    const treeDepth = Math.max(...nodesConnectionLengths);
    /** @type {import('./types/tree').ITreeNode[][]} */
    const treeLevels = [];

    /* 
      * Identify all nodes in tree and store them as jagged array (array of arrays) with first array consisting nodes in first level of tree and so on....
      * Eg: End result will look something like this- [[A, B], [X, Y, Z], [H, J]]  (@NOTE: this is a simplified representation of nodes)
      * Here A,B are root nodes X,Y,Z are nodes on 2nd level and H,J are leaf nodes 
    */
    for (let n = 0; n <= treeDepth; n++) {
      const nodesInNthLevel = treeNodes.filter(node => node.position.split('/').filter(connectionPath => connectionPath).length === n);
      if (nodesInNthLevel.length) {
        treeLevels.push(nodesInNthLevel);
      }
    }

    // add children nodes for connecting them to their parents
    // resulting tree will be generated in place as first array of 'treeLevels'
    treeLevels.forEach((nodes, index) => {
      nodes.forEach(node => {
        if (index < treeLevels.length - 1) {
          const childrenNodes = treeLevels[index + 1].filter(nextLevelNode => nextLevelNode.position.includes(node.nodeId));
          set(node, 'children', childrenNodes); // @NOTE: using set to add new reactive property on node Object, this is required in vue 2 due to reactivity caveats
          // node.children = childrenNodes; // @VUE3: uncomment this and remove above line, since these reactivity caveats doesn't exist in vue 3.
        }
      });
    });

    // store generated tree
    nestedTree.value = treeLevels[0];
    // store flat list
    flatTreeList.value = treeLevels.flatMap(nodes => nodes);
  };
  onMounted(async () => {
    generateTree(cloneList(props.value));
    await handleConnectionsUpdate();
    await delay(500);
    handleConnectionsUpdate(); // triggering connection update again to ensure all connections are positioned properly
  });

  //-- update tree logic --//

  // watcher for handling addition/deletion of nodes
  watch(() => props.value.length, () => {
    if (flatTreeList.value.length > props.value.length) {
      // handle deletion of nodes from tree
      const mappedNodeIds = props.value.map(node => node.nodeId);
      const nodesToBeDeleted = flatTreeList.value.filter(node => !mappedNodeIds.includes(node.nodeId));
      nodesToBeDeleted.forEach(node => {
        if (node.position) {
          // handle deletion of non-root nodes
          const parentNode = getParentNode(node);
          deleteNode(parentNode, node);
        } else {
          // handle deletion of root nodes
          nestedTree.value = nestedTree.value.filter(rootNode => rootNode.nodeId !== node.nodeId);
          flatTreeList.value = flatTreeList.value.filter(rootNode => rootNode.nodeId !== node.nodeId);
        }
        // handle descendants of deleted node
        if (node.children?.length) {
          handleDeletedNodeDescendants(node);
        }
      });
    } else if (flatTreeList.value.length < props.value.length) {
      // handle addition of nodes to tree
      const mappedNodeIds = flatTreeList.value.map(node => node.nodeId);
      const newNodeList = cloneList(props.value);
      const nodesToBeAdded = newNodeList.filter(node => !mappedNodeIds.includes(node.nodeId));
      nodesToBeAdded.forEach(node => {
        if (node.position) {
          // handle non-root nodes addition
          const parentNode = getParentNode(node);
          addNode(parentNode, node);
        } else {
          // handle root nodes addition
          nestedTree.value.push(node);
          flatTreeList.value.push(node);
        }
      });
    }
    handleInputEmit();
  });

  // watcher for updating non-primitive property of each node in local state
  debouncedWatch(() => props.value, () => {
    flatTreeList.value.forEach(node => {
      const propNode = props.value.find(pNode => pNode.nodeId === node.nodeId);
      if (propNode) {
        if (node.data !== propNode.data) {
          // update data if reference is changed in value prop
          node.data = propNode.data;
        }
      }
    });
  }, {
    deep: true,
    debounce: 500
  });

  /**
   * Moves descendants sub-tree of a deleted node to root level and purges all invalid connections
   * @param {import('./types/tree').ITreeNode} node
   */
  const handleDeletedNodeDescendants = node => {
    const descendants = getDescendants(node);
    descendants.forEach(descendantNode => {
      descendantNode.position = descendantNode.position.split(`/${node.nodeId}`)[1]; // update position from all descendants
    });
    // purge all connections of descendants connecting to parent tree
    purgeAllExternalConnections(descendants);
    // append children of node to root level (this creates dangling nodes i.e nodes decoupled from main tree)
    nestedTree.value.push(...node.children);
    // remove children from node
    node.children = [];
  };

  /**
   * Moves a node to root level
   * @param {import('./types/tree').ITreeNode} node 
   */
  const shiftNodeToRoot = node => {
    const parentNode = getParentNode(node);
    const descendants = getDescendants(node);
    descendants.forEach(descendantNode => {
      descendantNode.position = descendantNode.position.split(`/${parentNode.nodeId}`)[1]; // update position from all descendants
    });
    node.position = '';
    // remove node from parent's children
    parentNode.children = parentNode.children.filter(cNode => cNode.nodeId !== node.nodeId);
    // append node to root level (this creates dangling nodes i.e nodes decoupled from tree)
    nestedTree.value.push(node);
  };

  /**
   * Removes all other connections aside from connections within specified nodes 
   * @param {import('./types/tree').ITreeNode[]} nodes 
   */
  const purgeAllExternalConnections = nodes => {
    const nodeIds = nodes.map(dNode => dNode.nodeId);
    nodes.forEach(node => {
      const nodeConnections = getNodeConnections(flatTreeList.value, node);
      // purge all external connections
      const externalConnections = nodeConnections.filter(connection => !nodeIds.includes(connection.targetNodeId));
      externalConnections.forEach(connection => {
        deleteConnectionFromTree(flatTreeList.value, connection.id);
      });
    });
  };

  /**
   * Emits tree component state to parent component if there is any update
   */
  const handleInputEmit = () => {
    // emit value only if values are different
    const newValue = transformNodeList(flatTreeList.value);
    if (!areListsEqual(newValue, props.value)) {
      let emitValue = cloneList(newValue);
      emit('input', emitValue);
    }
    handleConnectionsUpdate();
    updateTreeScrollable();
  };

  /**
   * Removes children property from all nodes (children property is required for generating tree and intended for internal use by this component only)
   * @param {import('./types/tree').ITreeNode[]} nodes 
   */
  const transformNodeList = nodes => {
    return nodes.map(node => {
      const {
        children,
        ...restNode
      } = node;
      return restNode;
    });
  };

  /**
   * Add dangling nodes back to tree with its new parent
   ** Dangling nodes (nodes separated out of tree) are created from descendants of deleted parents
   ** All dangling nodes are added to root level while deleting their parent
   * @param {import('./types/tree').IDanglingNodeConnectionPayload} nodeConnection
   */
  const connectDanglingNode = nodeConnection => {
    const sourceNode = getNodeById(nodeConnection.sourceNodeId);
    const targetNode = getNodeById(nodeConnection.targetNodeId);
    if (sourceNode && targetNode) {
      sourceNode.position = `${targetNode.position}/${targetNode.nodeId}`;
      const descendants = getDescendants(sourceNode);
      if (descendants.length) {
        // update positions of all descendants
        descendants.forEach(descendantNode => {
          descendantNode.position = descendantNode.position.replace(`/${sourceNode.nodeId}`, `${sourceNode.position}/${sourceNode.nodeId}`);
        });
      }
      // remove dangling node from root level
      nestedTree.value = nestedTree.value.filter(rootNode => rootNode.nodeId !== sourceNode.nodeId);
      // remove dangling node from flat tree list
      flatTreeList.value = flatTreeList.value.filter(rootNode => rootNode.nodeId !== sourceNode.nodeId);
      // add dangling node to its new parent
      addNode(targetNode, sourceNode, nodeConnection.condition);
    }
  };
  /**
   * @param {import('./types/tree').IDanglingNodeConnectionPayload} nodeConnection
   */
  const handleDanglingNode = nodeConnection => {
    if (nodeConnection.sourceNodeId && nodeConnection.targetNodeId) {
      connectDanglingNode(nodeConnection);
      handleInputEmit();
    }
  };
  subscription.subscribe(ON_DANGLING_NODE_CONNECT, handleDanglingNode);

  /**
   * Adds a node as child of parent node
   * @param {import('./types/tree').ITreeNode} parentNode
   * @param {import('./types/tree').ITreeNode} newChildNode
   * @param {import('./types/tree').ICondition} condition
   */
  const addNode = (parentNode, newChildNode, condition = props.defaultCondition) => {
    if (parentNode) {
      // add new node as child of parent node
      if (!parentNode.children) {
        set(parentNode, 'children', []); // @NOTE: using set to add new reactive property on parentNode Object, this is required in vue 2 due to reactivity caveats
        // parentNode.children = []; // @VUE3: uncomment this and remove above line, since these reactivity caveats doesn't exist in vue 3.
      }
      parentNode.children.push(newChildNode);

      // add new node in flatTreeList
      flatTreeList.value.push(newChildNode);

      // add a connection from parent node
      const newConnection = generateConnection(newChildNode, {
        ...condition
      });
      parentNode.connections.push(newConnection);
    }
  };

  /**
   * Deletes a node from tree
   * @param {import('./types/tree').ITreeNode} parentNode 
   * @param {import('./types/tree').ITreeNode} node 
   */
  const deleteNode = (parentNode, node) => {
    if (parentNode) {
      // remove node from parent's children
      parentNode.children = parentNode.children.filter(childNode => childNode.nodeId !== node.nodeId);

      // remove node from flatTreeList
      flatTreeList.value = flatTreeList.value.filter(tNode => tNode.nodeId !== node.nodeId);

      // remove all connection associated with node
      const connections = getNodeConnections(flatTreeList.value, node);
      connections.forEach(connection => {
        deleteConnectionFromTree(flatTreeList.value, connection.id);
      });
    }
  };

  /**
   * Returns node for specified id
   * @param {string} nodeId 
   */
  const getNodeById = nodeId => {
    return flatTreeList.value.find(node => node.nodeId === nodeId);
  };

  /**
   * Returns parent node for the specified child node
   * @param {import('./types/tree').ITreeNode} childNode 
   */
  const getParentNode = childNode => {
    const parentNodeId = childNode.position.split('/').slice(-1)[0];
    return flatTreeList.value.find(node => node.nodeId === parentNodeId);
  };

  /**
   * @param {array} newValue 
   * @param {array} oldValue 
   */
  const areListsEqual = (newValue, oldValue) => {
    let isListEqual = null;
    if (newValue.length !== oldValue.length) {
      isListEqual = false;
    } else {
      isListEqual = !newValue.some(newNode => {
        const oldNode = oldValue.find(node => node.nodeId === newNode.nodeId);
        return !isEqual(newNode, oldNode); // stops loop if objects are not equal
      });
    }
    return isListEqual;
  };

  /**
   * Returns new array with shallow cloned objects
   * @template T
   * @param {T} nodeList
   */
  const cloneList = nodeList => {
    return nodeList.map(node => ({
      ...node
    }));
  };

  /**
   * @param {import('./types/tree').ITreeNode} node
   */
  const getDescendants = node => {
    return flatTreeList.value.filter(descendantNode => descendantNode.position.includes(node.nodeId));
  };

  //-- connection logic --//
  /**
   * @param {string} nodeId 
   */
  const handleMainConnectionDelete = nodeId => {
    const node = getNodeById(nodeId);
    if (node) {
      const descendants = getDescendants(node);
      purgeAllExternalConnections([...descendants, node]);
      shiftNodeToRoot(node);
    }
    handleInputEmit();
  };
  subscription.subscribe(ON_PARENT_CHILD_CONNECTION_DELETE, handleMainConnectionDelete);

  /**
   * @param {string} connectionId 
   */
  const handleConnectionSelect = connectionId => {
    const connections = flatTreeList.value.filter(node => node.connections?.length).flatMap(node => node.connections);
    emit('connection-selected', connections.find(connection => connection.id === connectionId));
  };
  subscription.subscribe(ON_CONNECTION_SELECT, handleConnectionSelect);
  const areConnectionsUpdating = ref(false);
  const handleConnectionsUpdate = async () => {
    areConnectionsUpdating.value = true;
    subscription.publish(ON_CONNECTIONS_UPDATE);
    await nextFrame(); // wait for connections to update in DOM
    areConnectionsUpdating.value = false;
  };
  return {
    treeElement,
    isTreeScrollable,
    nestedTree,
    areConnectionsUpdating
  };
};
__sfc_main.components = Object.assign({
  SubTree,
  TreeConnections
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import "~@/style/variables.scss";

.tree-container {
  position: relative;
  white-space: nowrap;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.content-center {
      align-items: center;
  }
  .tree-inner-container {
    width: max-content;
    transform-origin: top left;
    margin: 2rem 3.5rem;
  }
  .tree {
    >.tree-list {
      position: relative;
      transition: all 0.5s;
      >.tree-list-item {
          padding-top: 0;
      }
    }
  }
}
</style>
