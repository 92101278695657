import { ref, watch, set } from '@vue/composition-api';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useGrid } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/components/grid/grid';
import { debouncedWatch } from '@vueuse/core';

export const useBasicProperties = (selectedFieldType = 'field') => {
    const { selectedField } = useFormBuilder();
    const { selectedGridCells } = useGrid();

    const basicProperties = ref({});
    debouncedWatch(
        () => [selectedField.value, selectedGridCells.value],
        () => {
            if (selectedFieldType === 'gridCell') {
                // handle properties for grid cell
                if (selectedGridCells.value.length) {
                    const { rowIndex, columnIndex } = selectedGridCells.value[0];
                    const cellRow =  selectedField.value?.properties?.basic?.cells?.data[rowIndex];
                    const selectedCell = cellRow ? cellRow[columnIndex] : null;
                    basicProperties.value = selectedCell?.properties?.basic?.element.properties?.basic || {};
                } else {
                    basicProperties.value = {};
                }
            } else {
                // handle properties for field
                if (selectedField.value) {
                    basicProperties.value = selectedField.value.properties.basic;
                } else {
                    basicProperties.value = {};
                }

                // Adding default values for backward compatibility

                if(selectedFieldType === 'date' && typeof basicProperties.value.format === 'undefined'){
                    set(basicProperties.value,'format','');
                    set(basicProperties.value,'isCustomDateFormat',true);
                }
            }
        },
        {
            immediate: true,
            deep: true,
            debounce: 500
        }
    );

    return  {
        basicProperties
    };
};
