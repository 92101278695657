<template>
  <b-input
    type="email"
    size="is-small"
    :disabled="field.properties.basic.isDisabled"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    :value="field.properties.basic.hasDefaultValue ? field.properties.basic.defaultValue : ''"
  />
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>
