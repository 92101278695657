<template>
  <component
    :is="tag"
    :class="font"
  >
    <slot />
  </component>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  tag: {
    type: String,
    validator: val => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(val),
    default: 'h1'
  },
  font: {
    type: String,
    default: 'roboto'
  }
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
h1 {
    font-size: 3.5rem;
}
h2 {
    font-size: 3rem;
}
h3 {
    font-size: 2.5rem;
}
h4 {
    font-size: 2rem;
}
h5 {
    font-size: 1.5rem;
}
h6 {
    font-size: 1rem;
}

// fonts
.roboto {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
</style>
