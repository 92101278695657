<template>
  <div>
    <ModuleTable
      :modules="workflowStore.workflows"
      :loading="workflowStore.areWorkflowsLoading"
      :no-results-content="{
        bannerLabel: 'No workflows yet, create one now',
        buttonLabel: 'New Workflow'
      }"
      @delegate-action="handleTableAction"
    />
  </div>
</template>

<script >
// lib
import { onMounted, ref } from '@vue/composition-api';
// components
import ModuleTable from '@/modules/builder/components/shared/module-list-blocks/ModuleTable.vue';
// stores
import { useWorkflowStore } from '@/modules/builder/store/workflowStore';
// composables
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useModuleTable } from '@/modules/builder/components/shared/module-list-blocks/moduleTable';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const route = useRoute();
  const router = useRouter();
  const {
    tableActions
  } = useModuleTable();
  const workflowStore = useWorkflowStore();
  const handleEditingWorkflow = payload => {
    emit('edit', payload.id);
  };
  const handleCreatingWorkflow = () => {
    emit('create');
  };
  const handleTableAction = payload => {
    const {
      tableAction,
      selectedRow
    } = payload;
    const {
      EDIT,
      CREATE,
      DUPLICATE,
      DELETE,
      OPEN_VERSIONS,
      OPEN_ANALYTICS
    } = tableActions.value;
    const actionHandlers = {
      [EDIT]: handleEditingWorkflow,
      [CREATE]: handleCreatingWorkflow,
      [DUPLICATE]: workflowStore.fetchWorkflows,
      [DELETE]: workflowStore.fetchWorkflows,
      [OPEN_VERSIONS]: openVersions,
      [OPEN_ANALYTICS]: openAnalytics
    };
    const handler = actionHandlers[tableAction];
    if (handler) {
      handler(selectedRow);
    }
  };
  const openVersions = selectedWorkflow => {
    emit('close-list-modal');
    router.push('/application/' + route.params.appId + '/module/' + selectedWorkflow.id + '/versions');
  };
  const openAnalytics = () => {
    emit('close-list-modal');
    router.push('/analytics');
  };
  onMounted(async () => {
    const {
      appId,
      moduleId
    } = route.params;
    await workflowStore.fetchWorkflows(appId, moduleId);
  });
  return {
    workflowStore,
    handleTableAction
  };
};
__sfc_main.components = Object.assign({
  ModuleTable
}, __sfc_main.components);
export default __sfc_main;
</script>
