import { ref, set } from '@vue/composition-api';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useGrid } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/components/grid/grid';
import { debouncedWatch } from '@vueuse/core';
import { generateDefaultEventMapping } from '../form-elements/elementFactories';

const defaultEvents = [
    {
        label: 'Blur',
        value: 'blur'
    },
    {
        label: 'Click',
        value: 'click'
    },
    {
        label: 'Right Click',
        value: 'contextmenu'
    },
    {
        label: 'Double Click',
        value: 'dblclick'
    },
    {
        label: 'Focus',
        value: 'focus'
    },
    {
        label: 'Input',
        value: 'input'
    },
    {
        label: 'Change',
        value: 'change'
    },
    {
        label: 'Key Down',
        value: 'keydown'
    },
    {
        label: 'Key Up',
        value: 'keyup'
    },
    {
        label: 'Hover',
        value: 'mouseover'
    },
    {
        label: 'Scroll',
        value: 'scroll'
    },
    {
        label: 'Scroll End',
        value: 'scrollend'
    }
];
const fieldEventOptions = ref(
    defaultEvents
        .map(event => ({ ...event }))
        .sort((eventA, eventB) => eventA.label.localeCompare(eventB.label))
);

export const useFieldEvents = (selectedFieldType = 'field') => {
    const { selectedField } = useFormBuilder();
    const { selectedGridCells } = useGrid();

    const fieldEventMappings = ref([]);

    const getFieldEventMappings = (field) => {
        if (!('events' in field)) {
            // handle adding events mapping for backward compatibility(i.e older fields)
            set(field, 'events', []);
            const defaultMapping = generateDefaultEventMapping();
            field.events.push(defaultMapping);
        }
        return field.events;
    };

    /**
     * @param {string} eventValue
     */
    const getEventLabel = (eventValue) => (fieldEventOptions.value.find(eventOption => eventOption.value === eventValue) || { label: '' }).label;

    debouncedWatch(
        () => [selectedField.value, selectedGridCells.value],
        () => {
            if (selectedFieldType === 'gridCell') {
                // handle event mappings for grid cell
                if (selectedGridCells.value.length) {
                    const { rowIndex, columnIndex } = selectedGridCells.value[0];
                    const cellRow =  selectedField.value?.properties?.basic?.cells?.data[rowIndex];
                    const selectedCell = cellRow ? cellRow[columnIndex] : null;
                    fieldEventMappings.value = getFieldEventMappings(selectedCell);
                } else {
                    fieldEventMappings.value = [];
                }
            } else {
                // handle event mappings for field
                if (selectedField.value) {
                    fieldEventMappings.value = getFieldEventMappings(selectedField.value);
                } else {
                    fieldEventMappings.value = [];
                }
            }
        },
        {
            immediate: true,
            deep: true,
            debounce: 500
        }
    );

    return  {
        fieldEventMappings,
        fieldEventOptions,
        getEventLabel
    };
};
