<template>
  <div class="mt-2">
    <b-notification
      type="is-primary is-light"
      :closable="false"
      class="mb-5 mr-2 has-text-centered"
    >
      This module is executed <strong>{{ sidebarType === 'Always' ? 'always' : 'at specific times' }}.</strong>
    </b-notification>

    <BaseSelect
      v-model="sidebarType"
      label="Execution time"
    >
      <option
        v-for="(executionTime, index) in executionTimes"
        :key="index"
      >
        {{ executionTime }}
      </option>
    </BaseSelect>
    <SidebarLoader v-if="isSidebarLoading" />
    <component
      :is="sidebarComponentMap[sidebarType]" 
      v-else
      v-model="sidebarInfo"
    />
  </div>
</template>

<script >
// libs
import { computed, ref } from '@vue/composition-api';
// components
import Scheduler from '@/modules/builder/components/module-sidebar/start/Scheduler.vue';
import ExecuteAlways from '@/modules/builder/components/module-sidebar/start/ExecuteAlways.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import SidebarLoader from '@/modules/builder/components/module-sidebar/SidebarLoader.vue';
// composables
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const {
    isSidebarLoading,
    sidebarType
  } = useModuleSidebar();
  const startTypes = {
    ALWAYS: 'Always',
    SCHEDULER: 'Specific time / Cron job'
  };
  const executionTimes = ref([startTypes.ALWAYS, startTypes.SCHEDULER]);
  const sidebarComponentMap = ref({
    [startTypes.ALWAYS]: ExecuteAlways,
    [startTypes.SCHEDULER]: Scheduler
  });
  const sidebarInfo = computed({
    get() {
      return props.value;
    },
    set(newValue) {
      const payload = {
        ...newValue,
        type: sidebarType.value
      };
      emit('input', payload);
    }
  });
  return {
    isSidebarLoading,
    sidebarType,
    executionTimes,
    sidebarComponentMap,
    sidebarInfo
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  SidebarLoader
}, __sfc_main.components);
export default __sfc_main;
</script>
