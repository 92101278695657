<template>
  <BaseModal
    v-model="isVariableModalActive"
    :has-modal-card="true"
    :trap-focus="true"
    custom-class="variables-list-modal"
    aria-role="dialog"
    aria-label="Variables"
    aria-modal
  >
    <CardPopup
      class="w-500"
      :show-footer="false"
    >
      <template #header>
        <p class="modal-card-title">
          Variables
        </p>
        
        <div class="is-flex is-align-items-center">
          <b-button
            size="is-small"
            class="px-5 rounded-8 btn-primary-light mr-3"
            icon-left="plus"
            @click="handleAddingNewVariable()"
          >
            Add New
          </b-button>
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
          >
            <template #trigger>
              <b-icon
                class="cursor-pointer"
                icon="dots-horizontal"
              />
            </template>
            <b-dropdown-item
              aria-role="listitem"
              @click="exportVariables()"
            >
              <b-icon 
                icon="file-download-outline"
                size="is-small"
              />
              Export
            </b-dropdown-item>
          </b-dropdown>
          <button
            class="delete ml-1"
            aria-label="close"
            @click="isVariableModalActive = false;"
          />
        </div>
      </template>
      <template #body>
        <VariableTree
          :items="variables"
          :item-dropdown="VariableOptionsDropdown"
        />
        <UpdateVariableModal
          v-model="isUpdateVariableModalActive"
          :variable="variableToEdit"
          @save="updateVariable"
        />
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
// libs
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
// components
import UpdateVariableModal from './UpdateVariableModal.vue';
import VariableOptionsDropdown from './VariableOptionsDropdown.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import VariableTree from '@/modules/core/components/variable-tree/VariableTree.vue';
import { useBuefy } from '@/hooks/buefy';
// others
import { ON_VARIABLE_DELETE, ON_VARIABLE_EDIT, variablesBus } from './variablesBus';

//-- child components --//
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  appName: {
    type: String,
    default: ''
  },
  value: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- component events --//
  const emit = __ctx.emit;

  //-- use composable --//
  const route = useRoute();
  const moduleStore = useModuleStore();
  const buefy = useBuefy();
  const moduleGraphStore = useModuleGraphStore();

  //-- variables logic --//
  const isVariableModalActive = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  const variableToEdit = ref(null);
  const variables = computed(() => {
    const variables = [...(moduleStore.moduleVariables || []), ...(moduleStore.authModuleVariables || []), ...(moduleStore.environmentVariables || [])];
    variables.sort((a, b) => a.name.localeCompare(b.name));
    return variables.map(model => {
      if (model.node_id === null) {
        return {
          name: model.name,
          reference: model.reference,
          hasOptions: true,
          data: model
        };
      }
      return {
        name: model.name,
        reference: model.reference,
        data: model
      };
    });
  });
  /**
   * This function handles both adding/updating variable request to server 
   * @param {import('../../../../types/applicationTypes').IVariable} updatedVariable 
   */
  const updateVariable = async updatedVariable => {
    try {
      const {
        appId
      } = route.params;
      const {
        moduleId
      } = moduleStore;

      // update global variables info json in module
      const updatedVariablesInfo = [...moduleStore.globalVariablesInfo.filter(variable => variable.reference !== updatedVariable.reference), {
        name: updatedVariable.name,
        value: updatedVariable.value,
        reference: updatedVariable.reference,
        type: updatedVariable.type
      }];
      await moduleStore.updateGlobalVariablesInfo(appId, moduleId, updatedVariablesInfo);

      // update global variables
      await moduleStore.createVariable(appId, moduleId, {
        module_id: moduleId,
        node_id: null,
        node_name: 'GLOBAL',
        variables: [updatedVariable]
      });
      buefy.toast.open('Variable updated!');
    } catch (err) {
      console.error(err);
    }
  };
  const handleAddingNewVariable = () => {
    isUpdateVariableModalActive.value = true;
    variableToEdit.value = null;
  };
  const handleEditingVariable = item => {
    const globalVariable = moduleStore.globalVariablesInfo.find(variable => variable.reference === item.data.reference);
    if (globalVariable) {
      item.data = {
        ...globalVariable
      };
    }
    isUpdateVariableModalActive.value = true;
    variableToEdit.value = item.data;
  };
  variablesBus.subscribe(ON_VARIABLE_EDIT, handleEditingVariable);
  const handleDeletingVariable = item => {
    buefy.dialog.confirm({
      title: 'Deleting variable',
      message: 'Are you sure you want to <b>delete</b> this variable? This action cannot be undone.',
      confirmText: 'Delete variable',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        deleteVariable(item.data);
      }
    });
  };
  variablesBus.subscribe(ON_VARIABLE_DELETE, handleDeletingVariable);

  /**
   * @param {import('../../../../types/applicationTypes').IVariable} item 
   */
  const deleteVariable = async item => {
    try {
      const {
        appId
      } = route.params;
      const {
        moduleId
      } = moduleStore;
      const updatedVariablesInfo = moduleStore.globalVariablesInfo.filter(variable => variable.reference !== item.reference);
      await Promise.all([moduleStore.updateGlobalVariablesInfo(appId, moduleId, updatedVariablesInfo), moduleStore.deleteVariables(appId, moduleId, [item.reference])]);
      buefy.toast.open('Variable deleted!');
    } catch (err) {
      console.error(err);
    }
  };
  const exportVariables = () => {
    const data = moduleStore.moduleVariables.map(variable => {
      const names = variable.name.split(' > ');
      const node = variable.node_id ? moduleGraphStore.getNodeById(variable.node_id) : null;
      return {
        'ID': variable.reference,
        'Name': names.at(-1),
        'Node Name': node ? node.data.name : 'Global',
        'Node ID': variable.node_id || '',
        'Node Type': node?.type || '',
        'Node Subtype': node?.data?.type || ''
      };
    });
    const csvContent = 'data:text/csv;charset=utf-8,' + Object.keys(data[0]).join(',') + '\n' + data.map(item => Object.values(item).join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${moduleStore.moduleDetails.name}_variables.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //-- update variable modal logic --//
  const isUpdateVariableModalActive = ref(false);
  return {
    VariableOptionsDropdown,
    isVariableModalActive,
    variableToEdit,
    variables,
    updateVariable,
    handleAddingNewVariable,
    exportVariables,
    isUpdateVariableModalActive
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  VariableTree,
  UpdateVariableModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.variables-list-modal {
  .modal-card-body {
    padding-top: 0;
    .menu {
      max-width: 325px;
      overflow-y: auto;
      font-size: 0.85rem;
      .menu-list {
        display: block;
        li {
          ul {
            margin: 0.25rem 0.75em;
          }
          >a {
            padding: 0 0.75em;
            >.icon {
              &:first-child {
                i {
                  color: $primary;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
