import { createSection } from '@/modules/builder/components/form-builder/form-elements/elementFactories';

export const generateFormOptions = () => ({
    name: 'Form',
    custom_url_name: null,
    layout: null,
    application_id: null,
    module_id: null,
    node_direct_access: false
});

export const generateDefaultForm = () => ({
    ...generateFormOptions(),
    sections: [createSection()]
});
