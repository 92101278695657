<template>
  <div>
    <b-notification
      v-if="sidebarType === actionTypes.REMOTE_DATABASE_ACTIONS"
      type="is-danger is-light"
      :closable="false"
      class="mb-5 mr-2 has-text-centered"
    >
      <strong>Coming Soon</strong>
    </b-notification>
    <BaseLabel>Action type</BaseLabel>
    <div class="mb-4 sidebar-dropdown">
      <b-dropdown
        v-model="sidebarType"
        :expanded="true"
      >
        <template
          v-if="sidebarType"
          #trigger
        >
          <p>{{ sidebarType }}</p>
        </template>

        <template
          v-else
          #trigger
        >
          <p>&nbsp;</p>
        </template>

        <div
          v-for="(action, index) in allowedActionsList"
          :key="index"
        >
          <b-dropdown-item :value="action.type">
            <div class="dropdown-option-wrap">
              <div class="img-wrap">
                <b-icon :icon="action.icon" />
              </div>
              <div class="text-wrap">
                <h4>{{ action.type }}</h4>
                <p>{{ action.description }}</p>
              </div>
            </div>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </div>

    <SidebarLoader v-if="isSidebarLoading" />
    <component
      :is="sidebarComponentMap[sidebarType]"
      v-else
      v-model="sidebarInfo"
    />
  </div>
</template>

<script >
// libs
import { computed, ref } from '@vue/composition-api';
// components
import ApiConnector from '@/modules/builder/components/module-sidebar/action/ApiConnector';
import DataMapper from '@/modules/builder/components/module-sidebar/action/data-mapper/DataMapper.vue';
import EmailSender from '@/modules/builder/components/module-sidebar/action/EmailSender';
import FileCreator from '@/modules/builder/components/module-sidebar/action/FileCreator';
import DatabaseConnector from '@/modules/builder/components/module-sidebar/action/DatabaseConnector';
import ApiBuilder from '@/modules/builder/components/module-sidebar/action/ApiBuilder';
import ReadFile from '@/modules/builder/components/module-sidebar/action/ReadFile';
import MoveFile from '@/modules/builder/components/module-sidebar/action/MoveFile';
import LocalDatabase from '@/modules/builder/components/module-sidebar/action/local-database/LocalDatabase.vue';
import DocumentMerge from '@/modules/builder/components/module-sidebar/action/DocumentMerge';
import Redirection from '@/modules/builder/components/module-sidebar/action/Redirection';
import SftpUploader from '@/modules/builder/components/module-sidebar/action/SftpUploader.vue';
import VariableSetter from '@/modules/builder/components/module-sidebar/action/VariableSetter.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import SidebarLoader from '@/modules/builder/components/module-sidebar/SidebarLoader.vue';
// composables
import { actionTypes, useActionSidebar } from './actionSidebar';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const {
    allowedActionsList
  } = useActionSidebar();
  const {
    isSidebarLoading,
    sidebarType
  } = useModuleSidebar();
  const sidebarInfo = computed({
    get() {
      return props.value;
    },
    set(newValue) {
      const payload = {
        ...newValue,
        type: sidebarType.value
      };
      emit('input', payload);
    }
  });
  const sidebarComponentMap = ref({
    [actionTypes.API_BUILDER]: ApiBuilder,
    [actionTypes.API_CONNECTOR]: ApiConnector,
    [actionTypes.DATA_MAPPER]: DataMapper,
    [actionTypes.DOCUMENT_CREATOR]: FileCreator,
    [actionTypes.DOCUMENT_MERGER]: DocumentMerge,
    [actionTypes.EMAIL_SENDER]: EmailSender,
    [actionTypes.FILE_MOVER]: MoveFile,
    [actionTypes.FILE_READER]: ReadFile,
    [actionTypes.FILE_UPLOADER]: SftpUploader,
    [actionTypes.LOCAL_DATABASE_ACTIONS]: LocalDatabase,
    [actionTypes.REDIRECT]: Redirection,
    [actionTypes.REMOTE_DATABASE_ACTIONS]: DatabaseConnector,
    [actionTypes.VARIABLE_SETTER]: VariableSetter
  });
  return {
    actionTypes,
    allowedActionsList,
    isSidebarLoading,
    sidebarType,
    sidebarInfo,
    sidebarComponentMap
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  SidebarLoader
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/modules/core/components/generics/styles/label.scss';
@import '~@/style/variables.scss';

.sidebar-dropdown {
  .dropdown-menu {
    z-index: 1000;
  }

  .dropdown-trigger {
    border: 1px solid $grey-9;
    border-radius: 8px;
    padding: 6px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $dark;
    position: relative;
  }
  .dropdown-trigger:after {
    border: 3px solid transparent;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    transform-origin: center;
    border-color: $dark;
    margin-top: 0;
    width: 9px;
    height: 9px;
    right: 12px;
  }
  .dropdown-item.is-active {
    background-color: transparent;
    color: $dark;
  }
  .dropdown-option-wrap .img-wrap {
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    border-radius: 8px;
    margin-right: 20px;
  }
  .dropdown-option-wrap {
    display: flex;
    padding: 8px 10px;
    border-radius: 8px;
  }
  .dropdown-option-wrap .text-wrap h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $dark;
    margin-bottom: 4px;
  }

  .dropdown-option-wrap .text-wrap p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $dark;
  }
  .dropdown-item {
    padding: 0 15px;
  }
  .dropdown-item:hover {
    background-color: transparent;
  }
  .dropdown-item:hover .dropdown-option-wrap {
    background-color: $grey;
  }
}
</style>
