<template>
  <BaseModal
    v-model="isCreateWorkflowModalActive"
    :has-modal-card="true"
    :trap-focus="true"
    custom-class="workflow-modal"
    aria-role="dialog"
    aria-label="workflows"
    aria-modal
  >
    <CardPopup
      class="w-800"
      :show-footer="true"
    >
      <template #header>
        <p class="modal-card-title">
          New Workflow
        </p>
        <div class="is-flex is-align-items-center">
          <button
            class="delete"
            aria-label="close"
            @click="setCreateWorkflowModalVisibility(false)"
          />
        </div>
      </template>
      <template #body>
        <BaseInput 
          v-model="newWorkflow.name"
          label="Enter Name"
        />
        <BaseSelect 
          v-model="newWorkflow.levelId"
          label="Select Level"
        >
          <option 
            v-for="(level, lIndex) in options.workflowLevels"
            :key="'w-level' + lIndex"
            :value="level.value"
          >
            {{ level.label }}
          </option>
        </BaseSelect>
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="setCreateWorkflowModalVisibility(false)"
          >
            Cancel
          </b-button>
          <b-button
            type="is-primary"
            class="px-6 rounded-8"
            @click="createWorkflow()"
          >
            Create
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';

//-- lazy loaded components --//
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isCreateWorkflowModalActive = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  const options = ref({
    workflowLevels: [{
      label: 'Screen',
      value: 1
    }]
  });
  const newWorkflow = ref({
    name: '',
    levelId: 1
  });
  const setCreateWorkflowModalVisibility = visibility => {
    isCreateWorkflowModalActive.value = visibility;
  };
  const createWorkflow = () => {
    emit('create', newWorkflow.value);
    newWorkflow.value.name = '';
    setCreateWorkflowModalVisibility(false);
  };
  return {
    isCreateWorkflowModalActive,
    options,
    newWorkflow,
    setCreateWorkflowModalVisibility,
    createWorkflow
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  BaseInput,
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>
