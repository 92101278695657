<template>
  <div
    class="card columns mt-5 is-shadowless form-builder-section"
  >
    <div class="form-builder-section-header">
      <b-button
        size="is-small"
        class="mr-1"
        @click="openSectionProperties()"
      >
        Properties
      </b-button>
      <b-button
        size="is-small"
        type="is-danger"
        @click="confirmDeleteSection()"
      >
        <b-icon icon="trash-can" />
      </b-button>
    </div>
    <div
      v-for="(fields, columnIndex) in section.fields"
      :id="'section-' + sectionIndex + columnIndex"
      :key="columnIndex"
      class="column dropzone-area"
      :class="{
        'is-12': section.columns === '1',
        'is-6': section.columns === '2',
        'is-4': section.columns === '3',
        highlighted: targetSectionId === 'section-' + sectionIndex + columnIndex
      }"
      @click="updateSelectedField(columnIndex)"
    >
      <SectionFields
        :fields="fields"
      />
    </div>
  </div>
</template>

<script >
import SectionFields from './SectionFields';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useBuefy } from '@/hooks/buefy';
import { useDropzone } from '@/modules/builder/components/form-builder/drop-zone/dropZone';
const __sfc_main = {};
__sfc_main.props = {
  section: {
    type: Object,
    required: true
  },
  sectionIndex: {
    type: Number,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  //-- composables --//
  const buefy = useBuefy();
  const {
    selectedFieldIndices
  } = useFormBuilder();
  const {
    targetSectionId
  } = useDropzone();

  //-- section logic --//
  const openSectionProperties = () => {
    selectedFieldIndices.value = {
      sectionIndex: props.sectionIndex,
      columnIndex: null,
      fieldIndex: null,
      type: null
    };
  };
  const updateSelectedField = columnIndex => {
    selectedFieldIndices.value.columnIndex = columnIndex;
  };
  const confirmDeleteSection = () => {
    buefy.dialog.confirm({
      title: 'Deleting section',
      message: 'Are you sure you want to <b>delete</b> this section? This action cannot be undone.',
      confirmText: 'Delete section',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        emit('delete');
        buefy.toast.open('Section deleted!');
      }
    });
  };
  return {
    targetSectionId,
    openSectionProperties,
    updateSelectedField,
    confirmDeleteSection
  };
};
__sfc_main.components = Object.assign({
  SectionFields
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.form-builder-section-header {
  display: none;
  position: absolute;
  right: 0;
  top: -15px;
}

.form-builder-section {
  position: relative;
  border: 1px solid $grey-5;
  width: 100%;
  min-height: 200px;
  height: 100%
  h1 {
    font-weight: bolder;
    font-size: 2rem;
  }

  h2 {
    font-weight: bold;
    font-size: 1.6rem;
  }

  h3 {
    font-weight: normal;
    font-size: 1.3rem;
  }

  blockquote {
    display: block;
    padding-left: 1rem;
    margin: 1rem 0;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }

  ul {
    list-style-type: disc;
    padding-left: 20px !important;
  }

  ol {
    padding-left: 20px !important;
  }

  &:hover {
    .form-builder-section-header {
      display: inherit;
    }
  }
 
  .dropzone-area {
    &.highlighted {
      background: $grey-8;
    }
  }
}
</style>
