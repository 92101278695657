import { ref, onMounted, onUnmounted, computed } from '@vue/composition-api';

export const useResizable = (options = { min: 400, max: 800, calculateFrom: 'right' }) => {
    const elementWidth = ref(options.min);
    const isResizing = ref(false);
    let initialClientX = 0;
    let initialColumnWidth = 0;

    const isElementResizing = computed(() => isResizing.value);

    const setTextSelection = (isEnabled) => {
        if (isEnabled) {
            document.body.style.userSelect = '';
        } else {
            document.body.style.userSelect = 'none';
        }
    };

    const handleMouseMove = (event) => {
        if (isResizing.value) {
            let distanceX = event.clientX - initialClientX;
            if (options.calculateFrom === 'right') {
                distanceX = -distanceX;
            }
            const newWidth = initialColumnWidth + distanceX;
            if (newWidth > options.min && newWidth < options.max) {
                elementWidth.value = newWidth;
            }
        }
    };

    const handleMouseUp = () => {
        isResizing.value = false;
        setTextSelection(true);
        document.body.style.cursor = '';
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleResize = (event) => {
        isResizing.value = true;
        initialClientX = event.clientX;
        initialColumnWidth = elementWidth.value;
        setTextSelection(false);
        document.body.style.cursor = 'ew-resize';
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    onMounted(() => {
        document.addEventListener('mouseup', handleMouseUp);
    });

    onUnmounted(() => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
    });

    return {
        elementWidth,
        isElementResizing,
        handleResize
    };
};
