import { ref } from '@vue/composition-api';

export const nodeListDropdownTypes = {
    BACKGROUND_ACTION: 'Background Action',
    INTEGRATION: 'Integration',
    SCREEN: 'Screen',
    CONNECTION: 'Connection',
    END: 'End'
};

const allowedNodeListOptions = ref([]);

export const useModuleGraph = () => {
    return {
        allowedNodeListOptions
    };
};
