<template>
  <div>
    <BaseInput
      v-model="apiBuilderInfo.name"
      label="Node Name"
    />

    <BaseSelect
      v-model="apiBuilderInfo.apiType"
      label="Select API to create"
      @input="handleVariables"
    >
      <option
        v-for="(apiType, index) in options.apiTypes"
        :key="index"
      >
        {{ apiType }}
      </option>
    </BaseSelect>

    <div v-if="apiBuilderInfo.apiType === 'REST'">
      <BaseSelect
        v-model="apiBuilderInfo.rest.operation"
        label="API Operation"
        @input="handleVariables"
      >
        <option>Request</option>
        <option>Response</option>
      </BaseSelect>
      <div
        v-if="apiBuilderInfo.rest.operation === 'Request'"
      >
        <BaseSelect
          v-model="apiBuilderInfo.rest.request.methods"
          placeholder="e.g. POST"
          label="Methods"
          multiple
        >
          <option
            v-for="(method, index) in options.methods"
            :key="index"
          >
            {{ method }}
          </option>
        </BaseSelect>
        <BaseInput
          v-model="apiBuilderInfo.rest.request.resourceUrl"
          label="Resource Url"
        />
      </div>
      <div
        v-if="apiBuilderInfo.rest.operation === 'Response'"
      >
        <Editor
          v-model="apiBuilderInfo.rest.response.headers"
          label="Reponse Header"
          :input="true"
        />
        <Editor
          v-model="apiBuilderInfo.rest.response.statusCode"
          label="Response HTTP code"
          :input="true"
        />
        <Editor
          v-model="apiBuilderInfo.rest.response.data"
          label="Response data"
          :input="true"
        />
      </div>
    </div>

    <div v-else-if="apiBuilderInfo.apiType === 'BiPRO'">
      <BaseSelect
        v-model="apiBuilderInfo.bipro.operation"
        label="API Operation"
        @input="handleVariables"
      >
        <option>Request</option>
        <option>Response</option>
      </BaseSelect>

      <BaseSelect
        v-model="apiBuilderInfo.bipro.standard"
        label="Select a BiPRO standard"
      >
        <option
          v-for="(standard, index) in options.bipro.standards"
          :key="index"
        >
          {{ standard }}
        </option>
      </BaseSelect>

      <div
        v-if="apiBuilderInfo.bipro.standard === '430.4'"
        class="mb-2"
      >
        <BaseSelect
          v-model="apiBuilderInfo.bipro.fourThreeFour.method"
          label="Operation requested to the API"
        >
          <option
            v-for="(method, index) in options.bipro.methods"
            :key="index"
          >
            {{ method }}
          </option>
        </BaseSelect>
      </div>

      <div v-if="apiBuilderInfo.bipro.standard === '430.4' && apiBuilderInfo.bipro.operation === 'Response' && apiBuilderInfo.bipro.fourThreeFour.method === 'getShipment'">
        <div
          v-for="(section, index) in apiBuilderInfo.bipro.fourThreeFour.getShipment"
          :key="index"
          class="mb-2"
        >
          <hr>
          <div class="mb-2">
            <label>
              <strong>{{ section.name }}</strong>
            </label>
          </div>
          <div
            v-for="(field, idx) in section.fields"
            :key="idx"
            class="mb-3"
          >
            <Editor
              v-model="field.value"
              :input="true"
              :label="field.label"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="apiBuilderInfo.apiType === 'Custom'">
      <BaseSelect
        v-model="apiBuilderInfo.custom.operation"
        label="API Operation"
        @input="handleVariables"
      >
        <option>Request</option>
        <option>Response</option>
      </BaseSelect>

      <div v-if="apiBuilderInfo.custom.operation === 'Response'">
        <Editor
          v-model="apiBuilderInfo.custom.statusCode"
          :input="true"
          :formula="true"
          label="Response HTTP code"
          style="margin-bottom: 20px"
        />

        <Editor
          v-model="apiBuilderInfo.custom.data"
          :input="true"
          :formula="true"
          label="Response data"
          style="margin-bottom: 20px"
        />
      </div>
    </div>
  </div>
</template>

<script >
// libs
import { ref, watch } from '@vue/composition-api';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  // composables specific to this component
  const useSidebarVariables = () => {
    const defaultVariables = {
      BiPRO: [{
        reference: 'request.id.0',
        name: 'BiPRO.Request.ID'
      }, {
        reference: 'request.version.0',
        name: 'BiPRO.Request.Version'
      }],
      rest: [{
        reference: 'header',
        name: 'Header'
      }, {
        reference: 'body',
        name: 'Body'
      }, {
        reference: 'isAuthenticated',
        name: 'isAuthenticated'
      }, {
        reference: 'pathParams',
        name: 'Path Parameters'
      }, {
        reference: 'query',
        name: 'Query'
      }, {
        reference: 'method',
        name: 'Method'
      }, {
        reference: 'files',
        name: 'Files'
      }]
    };
    const createVariables = async () => {
      try {
        const {
          appId
        } = route.params;
        const {
          moduleId
        } = moduleStore;
        let variables = null;
        if (apiBuilderInfo.value.apiType === 'BiPRO' && apiBuilderInfo.value.bipro.operation === 'Request') {
          variables = defaultVariables.BiPRO.map(variable => ({
            ...variable
          }));
        } else if (apiBuilderInfo.value.apiType === 'REST' && apiBuilderInfo.value.rest.operation === 'Request') {
          variables = defaultVariables.rest.map(variable => ({
            ...variable,
            reference: `${moduleGraphStore.selectedNodeId}${variable.reference}`
          }));
        }
        if (variables) {
          await moduleStore.createVariable(appId, moduleId, {
            module_id: moduleId,
            node_id: moduleGraphStore.selectedNodeId,
            node_name: apiBuilderInfo.value.name,
            variables
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    const deleteStaleVariables = async () => {
      try {
        const {
          appId
        } = route.params;
        const {
          moduleId
        } = moduleStore;
        let references = [];
        if (apiBuilderInfo.value.apiType !== 'BiPRO') {
          references = defaultVariables.BiPRO.map(variable => variable.reference);
        } else if (apiBuilderInfo.value.apiType !== 'REST') {
          references = defaultVariables.rest.map(variable => `${moduleGraphStore.selectedNodeId}${variable.reference}`);
        }
        await moduleStore.deleteVariables(appId, moduleId, references);
      } catch (err) {
        console.error(err);
      }
    };
    const handleVariables = async () => {
      await deleteStaleVariables();
      await createVariables();
    };
    return {
      handleVariables
    };
  };

  // use composables
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const {
    handleVariables
  } = useSidebarVariables();
  const defaultValue = {
    name: 'APIBuilder',
    apiType: null,
    application_id: null,
    module_id: null,
    bipro: {
      operation: '',
      standard: '430.4',
      fourThreeFour: {
        method: '',
        getShipment: [{
          name: 'Transfer',
          fields: [{
            label: 'Sparte',
            value: '',
            info: ''
          }, {
            label: 'Antragsnummer',
            value: '',
            info: ''
          }, {
            label: 'Versicherungscheinnummer',
            value: '',
            info: ''
          }]
        }, {
          name: 'Vertrag',
          fields: [{
            label: 'Policierungsdatum',
            value: '',
            info: ''
          }, {
            label: 'Aenderungsdatum',
            value: '',
            info: ''
          }, {
            label: 'GueltigAbDatum',
            value: '',
            info: ''
          }, {
            label: 'GueltigBisDatum',
            value: '',
            info: ''
          }, {
            label: 'Zahlungsart',
            value: '',
            info: ''
          }, {
            label: 'Zahlungsweise',
            value: '',
            info: ''
          }, {
            label: 'Hauptfaelligkeit',
            value: '',
            info: ''
          }, {
            label: 'Abgangsdatum',
            value: '',
            info: ''
          }, {
            label: 'Abgangsgrund',
            value: '',
            info: ''
          }]
        }]
      }
    },
    custom: {
      operation: '',
      statusCode: '200',
      data: ''
    },
    rest: {
      operation: '',
      request: {
        methods: [],
        resourceUrl: '',
        authSecret: ''
      },
      response: {
        statusCode: '200',
        headers: '',
        data: ''
      }
    }
  };
  const apiBuilderInfo = ref({});
  const loadInfo = () => {
    apiBuilderInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => apiBuilderInfo.value, () => {
    emit('input', apiBuilderInfo.value);
  }, {
    deep: true
  });
  const options = ref({
    apiTypes: ['BiPRO', 'Custom', 'REST'],
    methods: ['POST', 'GET', 'PUT', 'PATCH', 'DELETE'],
    bipro: {
      standards: ['430.4'],
      methods: ['getShipment']
    }
  });
  return {
    handleVariables,
    apiBuilderInfo,
    options
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
