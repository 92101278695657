<template>
  <div>
    <div
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.hasLabel"
      >
        Show label
      </b-switch>
    </div>

    <div
      v-if="basicProperties.hasLabel"
      class="mt-2"
    >
      <BaseInput
        label="Label"
        :value="basicProperties.label"
        @input="handleVariablesUpdate"
      />
    </div>
  </div>
</template>

<script >
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const {
    selectedField
  } = useFormBuilder();
  const moduleGraphStore = useModuleGraphStore();
  const handleVariablesUpdate = async newValue => {
    basicProperties.value.label = newValue;
    // TO-DO's: Handle this in smart variables task (APPBLDR-221)

    // moduleGraphStore.updateVariableReferencesInAllScreens({
    //     newVariable: newValue,
    //     mentionId: selectedField.value.id
    // });
  };
  return {
    basicProperties,
    handleVariablesUpdate
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
