<template>
  <b-input
    size="is-small"
    type="password"
    :disabled="field.properties.basic.isDisabled"
    :password-reveal="field.properties.basic.isPasswordVisible"
    placeholder="*******"
  />
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>
