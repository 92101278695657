<template>
  <div
    class="tree-node"
    :data-node-id="node.nodeId"
  >
    <template v-if="customNode">
      <component
        :is="customNode.component"
        :node-id="node.nodeId"
      />
    </template>
    <template v-else>
      <span class="node">
        {{ node.data.content }}
      </span>
    </template>
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  nodeComponents: {
    type: Array,
    default: () => [],
    description: 'custom components to be rendered as tree nodes'
  },
  node: {
    type: Object,
    default: () => {},
    description: 'node passed recursively to generate tree'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const customNode = computed(() => props.nodeComponents.find(nodeComponent => props.node.type === nodeComponent.name));
  return {
    customNode
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
.node {
  border-radius: 0.5rem;
  padding: 0.25rem;
}
.tree-node {
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
}
</style>
