<template>
  <div>
    <b-checkbox
      v-for="(choice, cIndex) in field.properties.basic.choices"
      :key="cIndex"
      class="is-12"
      :class="!field.properties.basic.displayOptionsInRow ? 'column' : ''"
      size="is-small"
      native-value="default"
    >
      {{ choice }}
    </b-checkbox>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>
