<template>
  <div
    class="iframe-field"
  >
    IFrame
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.iframe-field {
    width: 100%; 
    border: 1px solid $grey-2; 
    height: 600px;
}
</style>
