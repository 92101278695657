import { ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';

const selectedGridCells = ref([]);
const selectedCellProperties = ref(null);

export const useGrid = () => {
    const selectGridCells = (data, rowIndex, columnIndex) => {
        selectedGridCells.value = [
            {
                rowIndex,
                columnIndex,
                data
            }
        ];
    };
    debouncedWatch(
        () => selectedGridCells.value,
        () => {
            if (selectedGridCells.value.length) {
                selectedCellProperties.value = selectedGridCells.value[0].data.properties.basic;
            }
        },
        {
            debounce: 500
        }
    );

    return {
        selectedGridCells,
        selectedCellProperties,
        selectGridCells
    };
};
