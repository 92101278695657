import { ref } from '@vue/composition-api';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';

const targetSectionId = ref('');

export const useDropzone = () => {
    const { selectedFieldIndices, formBuilderInfo } = useFormBuilder();

    const highlightSection = (element) => {
        if (!(element.from.id.includes('dropzone') && element.to?.parentElement.id.includes('section'))) {
            selectedFieldIndices.value = {
                sectionIndex: null,
                columnIndex: null,
                fieldIndex: null,
                type: null
            };
            targetSectionId.value = element.to.parentElement.id;
        }
    };

    const tryAddingSection = () => {
        formBuilderInfo.value.sections.forEach((section, sIndex) => {
            if (section.fields) {
                section.fields.forEach((columns, columnIndex) => {
                    columns.forEach((field, fieldIndex) => {
                        if (field.columns) {
                            formBuilderInfo.value.sections[sIndex].fields[columnIndex].splice(fieldIndex, 1);
                        }
                    });
                });
            }
            if (section.id) {
                formBuilderInfo.value.sections.splice(sIndex, 1);
            }

        });
        targetSectionId.value = '';
    };

    return {
        highlightSection,
        tryAddingSection,
        targetSectionId
    };
};
