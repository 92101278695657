<template>
  <b-table
    class="base-table"
    :class="variant"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template 
      v-for="(_, name) in $slots"
      #[name]
    >
      <slot 
        :name="name"
      />
    </template>
  </b-table>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  variant: {
    type: String,
    default: 'default'
  }
};
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
.base-table {}
</style>
