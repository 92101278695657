<template>
  <div>
    <BaseHeading
      tag="h4"
      class="mb-3 properties-heading"
    >
      Properties
    </BaseHeading>
    <SectionProperties 
      v-if="isSectionSelected"
    />
    <FieldProperties 
      v-if="isFieldSelected"
    />
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import FieldProperties from './field-properties/FieldProperties.vue';
import SectionProperties from './SectionProperties.vue';
import BaseHeading from '@/modules/core/components/generics/BaseHeading.vue';

//-- composable --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    selectedFieldIndices
  } = useFormBuilder();

  //-- selected logic --//
  const isSectionSelected = computed(() => selectedFieldIndices.value.sectionIndex !== null && selectedFieldIndices.value.fieldIndex === null);
  const isFieldSelected = computed(() => selectedFieldIndices.value.fieldIndex !== null);
  return {
    isSectionSelected,
    isFieldSelected
  };
};
__sfc_main.components = Object.assign({
  BaseHeading,
  SectionProperties,
  FieldProperties
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.properties-heading {
  font-size: 1.125rem !important;
  font-weight: 700;
}
</style>
