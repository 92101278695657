<template>
  <div class="document-merger">
    <BaseInput
      v-model="documentMergerInfo.name"
      label="Node name"
    />

    <div
      class="mb-2"
    >
      <BaseLabel>Documents to merge</BaseLabel>
      <Draggable
        v-if="documentMergerInfo.attachments && documentMergerInfo.attachments.length"
        v-model="documentMergerInfo.attachments"
        handle=".drag-handle"
      >
        <div
          v-for="(attachmentId, index) in documentMergerInfo.attachments"
          :key="index"
          class="is-flex mt-2"
        >
          <b-icon
            icon="drag-horizontal-variant"
            class="drag-handle mr-2"
          />
          <DynamicValueSwitch
            :value="documentMergerInfo.attachmentsMeta[attachmentId] ? documentMergerInfo.attachmentsMeta[attachmentId].dynamicValue : ''"
            :is-dynamic="documentMergerInfo.attachmentsMeta[attachmentId] ? documentMergerInfo.attachmentsMeta[attachmentId].isDynamic : false"
            :label="attachmentManager.getAttachmentName(attachmentId)"
            class="is-flex-grow-1"
            @input="attachmentManager.updateDynamicAttachment(attachmentId, $event)"
            @update-dynamic-switch="attachmentManager.updateDynamicAttachmentSwitch(attachmentId, $event)"
          >
            <template #default>
              <div
                v-if="documentMergerInfo.attachmentsMeta[attachmentId] ? !documentMergerInfo.attachmentsMeta[attachmentId].isDynamic : true"
                class="is-flex is-flex-grow-1 is-justify-content-space-between"
              >
                <div class="is-flex">
                  {{ attachmentManager.getAttachmentName(attachmentId) }}
                </div>
                <b-icon
                  icon="minus-circle"
                  class="remove-icon mr-2"
                  @click.native.stop="removeSelectedDocument(index)"
                />
              </div>
            </template>
          </DynamicValueSwitch>
        </div>
      </Draggable>
      <div v-else>
        None
      </div>
    </div>

    <div
      v-if="attachments && unselectedAttachments && unselectedAttachments.length"
      class="mb-2"
    >
      <BaseLabel>Documents</BaseLabel>
      <div
        v-for="(attachment, index) in unselectedAttachments"
        :key="index"
        class="mt-2"
      >
        <div class="is-flex is-justify-content-space-between">
          <div>
            {{ attachmentManager.getAttachmentName(attachment.nodeId) }}
          </div>
          <b-icon
            icon="plus-circle"
            class="add-icon mr-5"
            type="is-primary"
            @click.native.stop="addDocument(attachment.nodeId)"
          />
        </div>
      </div>
    </div>

    <Editor
      v-model="documentMergerInfo.fileName"
      :input="true"
      :formula="true"
      label="Document's new name"
    />
  </div>
</template>

<script >
// libs
import { computed, ref, set, watch, onMounted } from '@vue/composition-api';
import Draggable from 'vuedraggable';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  // composables specific to this component
  const useAttachmentManager = () => {
    const attachmentManager = {};
    attachmentManager.attachments = computed(() => moduleStore.moduleDocuments);
    attachmentManager.unselectedAttachments = computed(() => attachmentManager.attachments.value.filter(attachment => !documentMergerInfo.value.attachments.includes(attachment.nodeId)));
    attachmentManager.getAttachmentName = attachmentId => {
      const attachment = attachmentManager.attachments.value?.find(attachment => attachment.nodeId === attachmentId);
      if (attachment) {
        return stripContent(attachment.info.name + ' > ' + attachment.info.fileName + '.' + attachment.info.extension.toLowerCase());
      }
      return '';
    };
    attachmentManager.updateDynamicAttachment = (attachmentId, value) => {
      if (attachmentId in (documentMergerInfo.value?.attachmentsMeta || {})) {
        documentMergerInfo.value.attachmentsMeta[attachmentId].dynamicValue = value;
      } else {
        set(documentMergerInfo.value.attachmentsMeta, attachmentId, {
          isDynamic: true,
          dynamicValue: ''
        });
      }
    };
    attachmentManager.updateDynamicAttachmentSwitch = (attachmentId, isDynamic) => {
      if (isDynamic) {
        set(documentMergerInfo.value.attachmentsMeta, attachmentId, {
          isDynamic,
          dynamicValue: ''
        });
      } else {
        if (attachmentId in documentMergerInfo.value.attachmentsMeta) {
          delete documentMergerInfo.value.attachmentsMeta[attachmentId];
        }
      }
    };
    return attachmentManager;
  };

  // use composables
  const moduleStore = useModuleStore();
  const route = useRoute();
  const attachmentManager = useAttachmentManager();

  // destructure refs and computed for automatic unwrap by script setup
  const {
    attachments,
    unselectedAttachments
  } = attachmentManager;
  const defaultValue = {
    name: 'DocumentMerger',
    application_id: null,
    module_id: null,
    fileName: '',
    extension: 'PDF',
    attachments: [],
    attachmentsMeta: {}
  };
  const documentMergerInfo = ref({});
  const loadInfo = () => {
    documentMergerInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => documentMergerInfo.value, () => {
    emit('input', documentMergerInfo.value);
  }, {
    deep: true
  });
  const stripContent = string => string.replace(/<\/?[^>]+>/ig, '');
  const addDocument = attachmentId => {
    documentMergerInfo.value.attachments.push(attachmentId);
  };
  const removeSelectedDocument = index => {
    documentMergerInfo.value.attachments.splice(index, 1);
  };
  onMounted(async () => {
    const {
      appId
    } = route.params;
    const {
      moduleId
    } = moduleStore;
    await moduleStore.fetchModuleDocuments(appId, moduleId);
  });
  return {
    attachmentManager,
    attachments,
    unselectedAttachments,
    documentMergerInfo,
    addDocument,
    removeSelectedDocument
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseLabel,
  Draggable,
  DynamicValueSwitch,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.document-merger {
  .drag-handle {
    border-radius: 0.125rem;
    cursor: pointer;
    &:hover {
      background-color: $grey;
    }
  }
  .remove-icon, .add-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .remove-icon {
    color: rgba(black, 0.3);
  }
}
</style>
