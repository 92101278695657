
import { ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useGrid } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/components/grid/grid';

export const useValidationProperties = (propsTarget = 'field') => {
    const { selectedField } = useFormBuilder();
    const { selectedGridCells } = useGrid();

    const validationProperties = ref({});
    debouncedWatch(
        () => [selectedField.value, selectedGridCells.value],
        () => {
            if (propsTarget === 'gridCell') {
                if (selectedGridCells.value.length) {
                    const { rowIndex, columnIndex } = selectedGridCells.value[0];
                    const selectedCell = selectedField.value.properties.basic?.cells?.data[rowIndex][columnIndex];
                    validationProperties.value = selectedCell?.properties?.basic?.element.properties?.validation || {};
                } else {
                    validationProperties.value = {};
                }
            } else {
                if (selectedField.value) {
                    validationProperties.value = selectedField.value.properties.validation;
                } else {
                    validationProperties.value = {};
                }
            }
        },
        {
            immediate: true,
            deep: true,
            debounce: 500
        }
    );

    return  {
        validationProperties
    };
};
