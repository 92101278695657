<template>
  <div>
    <div class="is-flex is-justify-content-space-between mb-1">
      <BaseLabel>{{ label }}</BaseLabel>
      <b-switch
        :value="isRestrictedValueDynamic"
        @input="toggleDynamicSwitch"
      >
        Dynamic
      </b-switch>
    </div>
    <div
      v-if="isRestrictedValueDynamic"
      class="position-relative mb-2"
    >
      <Editor
        v-model="restrictedValue"
        :input="true"
        :formula="true"
      />
    </div>
    <b-datepicker
      v-else
      :value="restrictedValue ? new Date(restrictedValue) : null"
      locale="de-DE"
      class="mb-2"
      @input="updateDateRestrictedValue"
    />
  </div>
</template>

<script >
// lib
import { computed } from '@vue/composition-api';
import { useValidationProperties } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationProperties';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
const __sfc_main = {};
__sfc_main.props = {
  restrictedValueKey: {
    type: String,
    required: true
  },
  dynamicValueToggleKey: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- composables --//
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);

  //-- dynamic values logic --//
  const isRestrictedValueDynamic = computed({
    get() {
      return validationProperties.value[props.dynamicValueToggleKey] || false;
    },
    set(val) {
      if (validationProperties.value) {
        validationProperties.value[props.dynamicValueToggleKey] = val;
      }
    }
  });
  const toggleDynamicSwitch = isDynamic => {
    restrictedValue.value = '';
    isRestrictedValueDynamic.value = isDynamic;
  };

  //-- restricted value logic --//
  const restrictedValue = computed({
    get() {
      return validationProperties.value[props.restrictedValueKey] || '';
    },
    set(val) {
      if (validationProperties.value) {
        validationProperties.value[props.restrictedValueKey] = val;
      }
    }
  });
  const updateDateRestrictedValue = newValue => {
    restrictedValue.value = newValue.toISOString();
  };
  return {
    isRestrictedValueDynamic,
    toggleDynamicSwitch,
    restrictedValue,
    updateDateRestrictedValue
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
