// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useRoute } from '@/hooks/vueRouter';

export const useVariableManager = () => {
    const route = useRoute();
    const { formBuilderInfo } = useFormBuilder();
    const moduleGraphStore = useModuleGraphStore();
    const moduleStore = useModuleStore();

    const saveVariables = async () => {
        try {
            if (formBuilderInfo.value.name) {
                let variables = [];
                formBuilderInfo.value.sections.forEach(section => {
                    if (section.fields) {
                        section.fields.forEach(column => {
                            column.forEach(field => {
                                if (field.properties?.basic?.label) {
                                    if (field.type === 'address') {
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + field.properties.basic.labels.street,
                                            reference: 'street_' + field.id
                                        });
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + field.properties.basic.labels.number,
                                            reference: 'number_' + field.id
                                        });
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + field.properties.basic.labels.zipCode,
                                            reference: 'zipCode_' + field.id
                                        });
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + field.properties.basic.labels.city,
                                            reference: 'city_' + field.id
                                        });
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + field.properties.basic.labels.country,
                                            reference: 'country_' + field.id
                                        });
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + field.properties.basic.labels.state,
                                            reference: 'state_' + field.id
                                        });
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + field.properties.basic.labels.district,
                                            reference: 'district_' + field.id
                                        });
                                    } else if(field.type === 'tile'){
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + 'id',
                                            reference: 'id_' + field.id
                                        });
                                        variables.push({
                                            name: field.properties.basic.label + ' > ' + 'label',
                                            reference: 'label_' + field.id});  
                                    }
                                    else if (field.type === 'grid') {
                                        variables = variables.concat(field.properties.basic.cells.data.flatMap(
                                            (row, rowIndex) => row.map((cell, columnIndex) => {
                                                const { id, type } = cell.properties.basic.element;
                                                if (id && type !== 'text') {
                                                // avoids creating variables for empty cells and cells having plain text fields.
                                                    return {
                                                        name: `${field.properties.basic.label} > ${field.properties.basic.columns.names[columnIndex]} > ${field.properties.basic.rows.names[rowIndex]}`,
                                                        reference: cell.properties.basic.element.id
                                                    };
                                                }
                                                return null;
                                            }).filter(variable => variable)
                                        ));
                                    } else {
                                        variables.push({
                                            name: field.properties.basic.label,
                                            reference: field.id
                                        });
                                    }
                                }
                                else if (field.type === 'tariff-comparison') {
                                    variables.push({
                                        name: 'Selected tariff > title',
                                        reference: 'title_' + field.id
                                    });
                                    variables.push({
                                        name: 'Selected tariff > premium',
                                        reference: 'premium_' + field.id
                                    });
                                    variables.push({
                                        name: 'Selected tariff > description',
                                        reference: 'description_' + field.id
                                    });
                                    variables.push({
                                        name: 'Selected tariff > protections',
                                        reference: 'protections_' + field.id
                                    });
                                } else if (field.type === 'payment-gateway') {
                                    variables.push({
                                        name: 'Payment Gateway',
                                        reference: field.id
                                    });
                                }
                            });
                        });
                    }
                });
                const { appId } = route.params;
                const { moduleId } = moduleStore;
                await moduleStore.createVariable(appId, moduleId, {
                    module_id: moduleId,
                    node_id: moduleGraphStore.selectedNodeId,
                    node_name: formBuilderInfo.value.name,
                    variables
                });
            } else {
                window.scrollTo(0, 0);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        saveVariables
    };
};
