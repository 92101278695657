// @ts-check
import { getParentNode, isChildNode } from './treeFlowInternal';
import { ref } from '@vue/composition-api';

/** @type {import('@vue/composition-api').Ref<import('./types/treeConnections').ITreeConnectionOffset[]>} */
const treeConnectionOffsets = ref([]);

export const useTreeConnections = () => {

    /**
     * @param {import('./types/tree').ITreeNode[]} treeNodes
     */
    const computeConnectionOffsets = (treeNodes) => {
        treeConnectionOffsets.value = treeNodes
            .filter(node => node.connections.length)
            .flatMap(node => {
                const sourceNodeRect = getNodeElementBoundingRect(node.nodeId);

                /** @type {import('./types/treeConnections').ITreeConnectionOffset[]} */
                const connections = node.connections.map(connection => {
                    const targetNodeRect = getNodeElementBoundingRect(connection.targetNodeId);
                    const sourceTargetDeltaX = sourceNodeRect.x - targetNodeRect.x;
                    const sourceTargetDeltaY = sourceNodeRect.y - targetNodeRect.y;

                    const targetNode = treeNodes.find(node => node.nodeId === connection.targetNodeId);

                    /** If true, source and target node are connected directly via additional connection otherwise target node is connected as child of source node. */
                    const isDirectConnection = !isChildNode(node.nodeId, targetNode);

                    return {
                        sourceTargetDeltaX,
                        sourceTargetDeltaY,
                        sourceNodeId: node.nodeId,
                        targetNodeId: connection.targetNodeId,
                        connectionId: connection.id,
                        xDirectionFromSource: getConnectionDirectionFromSource(sourceTargetDeltaX),
                        offsets: [],
                        isDirectConnection
                    };
                });
                if (connections.length) {
                    /** left side connections with respect to source node */
                    const leftSideConnections = (connections.filter(connection => connection.sourceTargetDeltaX > 0).sort((connectionA, connectionB) => connectionA.sourceTargetDeltaX - connectionB.sourceTargetDeltaX));
                    /** right side connections with respect to source node */
                    const rightSideConnections = (connections.filter(connection => connection.sourceTargetDeltaX < 0).sort((connectionA, connectionB) => connectionB.sourceTargetDeltaX - connectionA.sourceTargetDeltaX));
                    /** straight connections with respect to source node */
                    const starightConnections = connections.filter(connection => connection.sourceTargetDeltaX === 0);

                    leftSideConnections.forEach((connection, index) => {
                        connection.offsets = [
                            {
                                lineNumber: 1,
                                nodeId: node.nodeId,
                                xOffset: 0,
                                yOffset: 25 * (index + 1)
                            },
                            {
                                lineNumber: 2,
                                nodeId: connection.isDirectConnection ? node.nodeId : null,
                                xOffset: connection.isDirectConnection ? 25 * (index + 1) : 0,
                                yOffset: 25 * (index + 1)
                            },
                            {
                                lineNumber: 3,
                                nodeId: connection.isDirectConnection ? node.nodeId : null,
                                xOffset: connection.isDirectConnection ? 25 * (index + 1) : 0,
                                yOffset: 25 * (index + 1)
                            }
                        ];
                    });
                    rightSideConnections.forEach((connection, index) => {
                        connection.offsets = [
                            {
                                lineNumber: 1,
                                nodeId: node.nodeId,
                                xOffset: 0,
                                yOffset: 25 * (index + 1)
                            },
                            {
                                lineNumber: 2,
                                nodeId: connection.isDirectConnection ? node.nodeId : null,
                                xOffset: connection.isDirectConnection ? 25 * (index + 1) : 0,
                                yOffset: 25 * (index + 1)
                            },
                            {
                                lineNumber: 3,
                                nodeId: connection.isDirectConnection ? node.nodeId : null,
                                xOffset: connection.isDirectConnection ? 25 * (index + 1) : 0,
                                yOffset: 25 * (index + 1)
                            }
                        ];
                    });
                    starightConnections.forEach((connection, index) => {
                        connection.offsets = [
                            {
                                lineNumber: 1,
                                nodeId: null,
                                xOffset: 0,
                                yOffset: 0
                            },
                            {
                                lineNumber: 2,
                                nodeId: null,
                                xOffset: 0,
                                yOffset: 0
                            },
                            {
                                lineNumber: 3,
                                nodeId: null,
                                xOffset: connection.isDirectConnection ? 25 * (index + 1) : 0,
                                yOffset: 0
                            }
                        ];
                    });
                } 
                return connections;
            });

        // handle offsets of direct connections
        treeConnectionOffsets.value
            .filter(connection => connection.isDirectConnection)
            .forEach(connection => {
                const targetNode = treeNodes.find(node => node.nodeId === connection.targetNodeId);
                const parentNode = getParentNode(treeNodes, connection.targetNodeId);
                const nodeId = targetNode.isStart ? targetNode?.nodeId : parentNode?.nodeId;
                if (nodeId) {
                    const parentNodeOffsets = getConnectionOffsetsByNodeIds([nodeId]);
                    const maxYOffset = parentNodeOffsets.length ? Math.max(...parentNodeOffsets.map(offset => offset.yOffset)) : 0;
                    const nDirectConnections = treeConnectionOffsets.value.filter(c => {
                        const line5 = getConnectionLineOffset(c.connectionId, 5);
                        return c.targetNodeId === connection.targetNodeId && line5.xOffset !== 0 && c.xDirectionFromSource === connection.xDirectionFromSource;
                    }).length;
                    const computedYOffset = targetNode.isStart ? 25 * (nDirectConnections + 1) : maxYOffset + 25;
                    connection.offsets.push({
                        lineNumber: 4,
                        nodeId,
                        xOffset: 0,
                        yOffset: computedYOffset
                    });
                    connection.offsets.push({
                        lineNumber: 5,
                        nodeId: null,
                        xOffset: 20 * (nDirectConnections + 1),
                        yOffset: computedYOffset
                    });
                }
            });
    };

    /**
     * @param {string} connectionId 
     */
    const getConnectionOffset = (connectionId) => treeConnectionOffsets.value.find(connectionOffset => connectionOffset.connectionId === connectionId);

    /**
     * @param {string} nodeId
     */
    const getNodeElementBoundingRect = (nodeId) => {
        if (nodeId) {
            const nodeElement = document.querySelector(`[data-node-id="${nodeId}"]`);
            return nodeElement ? nodeElement.firstElementChild.getBoundingClientRect() : null;
        }
        return null;
    };

    /**
     * @param {string} connectionId
     * @param {number} lineNumber 
     */
    const getConnectionLineOffset = (connectionId, lineNumber) => {
        const connection = getConnectionOffset(connectionId);
        let lineOffsetDetails = null;
        if (connection) {
            lineOffsetDetails = connection.offsets.find(line => line.lineNumber === lineNumber);
        }
        if (!lineOffsetDetails) {
            lineOffsetDetails = {
                lineNumber,
                nodeId: null,
                xOffset: 0,
                yOffset: 0
            };
        }
        return lineOffsetDetails;
    };

    /**
     * Returns connection offet details of specified nodes
     * @param {string[]} nodeIds 
     */
    const getConnectionOffsetsByNodeIds = (nodeIds) => {
        nodeIds = nodeIds.filter(nodeId => nodeId);
        const connectionOffsets = treeConnectionOffsets.value
            .flatMap(connection => connection.offsets.map(cOffset => ({
                ...cOffset,
                sourceNodeId: connection.sourceNodeId,
                targetNodeId: connection.targetNodeId,
                isDirectConnection: connection.isDirectConnection,
                xDirectionFromSource: connection.xDirectionFromSource
            })))
            .filter(connectionOffset => nodeIds.includes(connectionOffset.nodeId));
        
        return connectionOffsets;
    };

    /**
     * @param {number} sourceTargetDeltaX 
     */
    const getConnectionDirectionFromSource = (sourceTargetDeltaX) => sourceTargetDeltaX > 0 ? 'left' : (sourceTargetDeltaX < 0 ? 'right' : 'straight');
    
    return {
        treeConnectionOffsets,
        computeConnectionOffsets,
        getConnectionOffset,
        getConnectionLineOffset,
        getNodeElementBoundingRect,
        getConnectionOffsetsByNodeIds,
        getConnectionDirectionFromSource
    };
};
