<template>
  <div>
    <div
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.hasLimit"
      >
        Text max limit
      </b-switch>
    </div>

    <div
      v-if="basicProperties.hasLimit"
      class="mt-2"
    >
      <BaseInput
        v-model="basicProperties.limit"
        label="Limit"
      />
    </div>
  </div>
</template>

<script >
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  return {
    basicProperties
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
