<template>
  <div
    :class="'depth-' + depth.toString()"
    class="vqb-group-main"
  >
    <div class="add-item">
      <b-button
        v-if="depth > 1"
        class="mb-5 float-right vqb-delete-group-btn"
        size="is-small"
        variant="danger"
        @click="remove"
      >
        X
      </b-button>

      <div class="mb-2">
        <b-button
          v-for="mt in labels.matchTypes"
          :key="mt.id"
          :type="query.logicalOperator === mt.id ? 'is-primary' : 'is-link is-light'"
          @click="updateLogicalOperator(mt.id)"
        >
          {{ mt.label === 'All' ? 'AND': 'OR' }}
        </b-button>
        <span class="tag is-medium mt-1">is TRUE</span>
      </div>

      <div class="position-relative">
        <!-- <BaseSelect
          v-model="selectedRule"
          label="Select field"
          class="mt-2"
          expanded
        >
          <option
            v-for="(rule, index) in rules"
            :key="index"
            :value="rule"
          >
            {{ rule.label }}
          </option>
        </BaseSelect> -->
        <BaseLabel class="mt-2">
          Select field
        </BaseLabel>
        <b-button
          class="vqb-variable-popup-toggle-btn"
          @click="setVariablePopupVisibility(!isVariablePopupVisible)"
        >
          <div class="vqb-btn-content">
            <span>
              {{ selectedRule ? selectedRule.label : '' }}
            </span>
            <b-icon
              icon="chevron-down"
              variant="light"
            />
          </div>
        </b-button>
        <VariableTreePopup
          v-if="isVariablePopupVisible"
          class="vqb-variable-popup"
          :items="variableTreeItems"
          @node-selected="selectRule($event)"
        />
        <b-button
          type="is-primary"
          class="add-rule mt-4"
          @click="addRule"
        >
          <b-icon
            size="is-small"
            icon="plus"
            variant="light"
          />
          {{ labels.addRule }}
        </b-button>
      </div>
    </div>

    <div class="vqb-group-body">
      <query-builder-children v-bind="$props" />
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import QueryBuilderGroup from 'vue-query-builder/dist/group/QueryBuilderGroup.umd.js';
import QueryBuilderRule from './NuclicoreQueryBuilderRule.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import VariableTreePopup from '@/modules/core/components/variable-tree-popup/VariableTreePopup.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';

export default {
    name: 'QueryBuilderGroup',
    components: {
        QueryBuilderRule: QueryBuilderRule,
        BaseSelect,
        VariableTreePopup,
        BaseLabel
    },
    extends: QueryBuilderGroup,
    data: () => ({
        models: [],
        nclRules: [],
        isVariablePopupVisible: false
    }),
    computed: {
        variableTreeItems () {
            return this.rules ? this.rules.map(rule => {
                return {
                    name: rule.label,
                    reference: rule.id,
                    data: rule
                };
            }) : [];
        }
    },
    mounted() {

    },
    methods: {
        updateLogicalOperator(value) {
            this.query.logicalOperator = value;
        },
        selectRule (rule) {
            this.selectedRule = rule.data;
            this.setVariablePopupVisibility(false);
        },
        setVariablePopupVisibility (visibility) {
            this.isVariablePopupVisible = visibility;
        },
    }
};
</script>

<style lang="scss">
.vqb-group-main {
  position: relative;
  /* Negative Margin to offset the default column gap of 0.75rem in Bulma */
  margin: 0.75rem -0.75rem -0.75rem -0.75rem;
}

.vqb-delete-group-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.add-item {
  padding: 20px;
	background: rgba(238, 238, 239, 0.5);
  font-family: 'Roboto', sans-serif;
}

.add-item .tag {
  font-size: 14px;
  line-height: 16px;
}

.add-item button {
  border-radius: 8px;
	font-size: 14px;
	line-height: 16px;
	height: 40px;
	padding: 12px 20px;
  min-width: 100px;
  font-family: 'Roboto', sans-serif;
}

.add-item button.add-rule {
  width: 206px;
}

.add-item label {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;	
	color: #222222;
	display: block;
  margin-top: 20px;
}

.add-item select {
  font-family: 'Roboto', sans-serif;
}

.vqb-variable-popup-toggle-btn {
  display: block;
  width: 100%;
  height: 2.2rem !important;
  padding: 0 1rem !important;
  line-height: unset !important;
  .vqb-btn-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .icon {
    i {
      &:before {
        vertical-align: middle;
      }
    }
  }
}

.vqb-variable-popup {
  position: absolute;
  width: 100% !important;
  z-index: 30;
}

@media (min-width: 768px) {
  .vue-query-builder .vqb-rule.form-inline .form-group {
    display: block;
  }
}
</style>