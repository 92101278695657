<template>
  <div>
    <small>Node name</small>
    <b-input
      v-model="redirectInfo.name"
      size="is-small"
      class="mb-2"
    />
    <small>URL to redirect</small>
    <editor
      v-model="redirectInfo.url"
      :input="true"
      :formula="true"
    />
    <DynamicValueSwitch
      v-model="redirectInfo.openInNewTab"
      :is-dynamic="redirectInfo.isOpenInNewTabDynamic"
      label="Open in new tab"
      class="mt-3"
      @update-dynamic-switch="onSwitchUpdate"
    />
  </div>
</template>

<script >
import Editor from '@/modules/core/components/wysiwyg/Editor';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
import { ref, set, watch } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const defaultValue = {
    name: 'Redirect',
    application_id: null,
    module_id: null,
    url: '',
    openInNewTab: false,
    isOpenInNewTabDynamic: false
  };
  const redirectInfo = ref({});
  const loadInfo = () => {
    redirectInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => redirectInfo.value, () => {
    emit('input', redirectInfo.value);
  }, {
    deep: true
  });
  const onSwitchUpdate = value => {
    set(redirectInfo.value, 'isOpenInNewTabDynamic', value);
  };
  return {
    redirectInfo,
    onSwitchUpdate
  };
};
__sfc_main.components = Object.assign({
  Editor,
  DynamicValueSwitch
}, __sfc_main.components);
export default __sfc_main;
</script>
