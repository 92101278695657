<template>
  <div>
    <BaseInput
      v-model="fileReaderInfo.name"
      label="Node name"
    />

    <b-switch
      v-model="fileReaderInfo.remoteFile"
      class="mb-4"
    >
      Is file remote (on FTP/SFTP/Cloud)?
    </b-switch>
  
    <div v-if="fileReaderInfo.remoteFile">
      <BaseInput
        v-model="fileReaderInfo.remoteOptions.host"
        label="Host"
      />

      <BaseInput
        v-model="fileReaderInfo.remoteOptions.user"
        label="User"
      />

      <BaseInput
        v-model="fileReaderInfo.remoteOptions.password"
        type="password"
        label="Password"
      />

      <BaseInput
        v-model="fileReaderInfo.remoteOptions.port"
        type="number"
        label="Port"
      />

      <BaseInput
        v-model="fileReaderInfo.remoteOptions.folder"
        label="Folder structure"
      />

      <b-switch
        v-model="fileReaderInfo.remoteOptions.getAllFilesInFolder"
        class="mb-4"
      >
        Get all files in the folder?
      </b-switch>

      <div
        v-if="!fileReaderInfo.remoteOptions.getAllFilesInFolder"
        class="mb-4"
      >
        <BaseInput
          v-model="fileReaderInfo.remoteOptions.fileName"
          label="File name"
        />
      </div>
    </div>
    <b-switch
      v-model="fileReaderInfo.readingOptions.labelsInFirstLine"
      class="mb-4"
    >
      Are the labels presented in the first line?
    </b-switch>

    <div v-if="fileReaderInfo.remoteFile">
      <b-button
        expanded
        type="is-primary"
        class="mb-3"
        @click="testConnection"
      >
        Test the connection
      </b-button>
      <b-message
        v-if="connectionOK"
        size="is-small"
        type="is-success"
      >
        Connection successful
      </b-message>
      <b-message
        v-if="!connectionOK && connectionTestSent"
        size="is-small"
        type="is-danger"
      >
        There is a problem with a connection.
      </b-message>
    </div>
  </div>
</template>

<script >
// libs
import { ref, watch } from '@vue/composition-api';
// components
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
// services
import { parseModuleService } from '@/services/interpreter-service/interpreterModuleRequests';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const defaultValue = {
    name: 'FileReader',
    application_id: null,
    module_id: null,
    remoteFile: true,
    remoteOptions: {
      type: 'SFTP',
      host: '',
      user: '',
      password: '',
      port: '22',
      folder: '/',
      getAllFilesInFolder: true,
      fileName: ''
    },
    readingOptions: {
      labelsInFirstLine: true
    }
  };
  const fileReaderInfo = ref({});
  const loadInfo = () => {
    fileReaderInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => fileReaderInfo.value, () => {
    emit('input', fileReaderInfo.value);
  }, {
    deep: true
  });

  //-- test connection logic --//
  const connectionOK = ref(false);
  const connectionTestSent = ref(false);
  const testConnection = async () => {
    try {
      const {
        appId
      } = route.params;
      const {
        moduleId
      } = moduleStore;
      await parseModuleService(moduleId, {
        ...fileReaderInfo.value,
        application_id: appId,
        node_id: moduleGraphStore.selectedNodeId,
        node_name: fileReaderInfo.value.name
      });
      connectionOK.value = true;
      await moduleStore.fetchVariables(appId);
    } catch (err) {
      console.error(err);
      connectionTestSent.value = true;
    }
  };
  return {
    fileReaderInfo,
    connectionOK,
    connectionTestSent,
    testConnection
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
