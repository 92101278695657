<template>
  <div>
    <section>
      <!-- eslint-disable vue/no-mutating-props -->
      <Draggable
        v-model="variables"
        :sort="!areChildren"
        @sort="sortVariables()"
      >
        <!-- eslint-enable vue/no-mutating-props -->
        <b-collapse
          v-for="(variable, variableIndex) in variables"
          :key="variableIndex"
          :open="false"
          class="mb-2 variable-builder"
          animation="slide"
        >
          <template #trigger="slotProps">
            <div
              class="mb-2 trigger-container"
              role="button"
              aria-controls="contentIdForA11y3"
            >
              <div
                class="px-2 py-2 is-clipped"
              >
                <span
                  v-if="variable.name !== ''"
                  style="font-weight: 700;"
                >
                  {{ variable.name }}
                </span>
                <span
                  v-else
                  style="color: rgb(136, 136, 136);"
                >
                  -- enter a variable name --
                </span>
              </div>

              <div class="px-2 py-2 delete-div">
                <b-button @click="deleteProperty(variableIndex)">
                  <b-icon
                    icon="delete-outline"
                    type="is-danger"
                  />
                </b-button>
              </div>

              <div class="px-2 py-2">
                <b-icon :icon="slotProps.open ? 'menu-down' : 'menu-up'" />
              </div>
            </div>
          </template>

          <template #default>
            <div class="columns mb-0">
              <div class="column">
                <BaseInput
                  v-model="variable.name"
                  label="Variable name"
                  variant="list"
                />
              </div>
              <div class="column">
                <BaseSelect
                  v-model="variable.type"
                  label="Variable type"
                  variant="list"
                >
                  <option
                    v-for="(type, typeIndex) in types"
                    :key="typeIndex"
                  >
                    {{ type }}
                  </option>
                </BaseSelect>
              </div>
            </div>

            <div v-if="['String', 'Number', 'Boolean'].includes(variable.type)">
              <editor
                v-model="variable.value"
                :formula="true"
                :input="true"
                label="Variable value"
              />
            </div>

            <div
              v-if="variable.type === 'Object'"
              class="nested"
            >
              <VariablesBuilderBlock :variables="variable.children" />
            </div>

            <div
              v-if="variable.type === 'Array'"
              class="nested"
            >
              <b-switch
                v-model="variable.dynamic"
              >
                Use dynamic value / variable
              </b-switch>
              <div v-if="variable.dynamic">
                <Editor
                  v-model="variable.value"
                  :formula="true"
                  :input="true"
                  label="Dynamic array"
                />
              </div>
              <div v-else>
                <div
                  v-for="(arrayVariable, arrayIndex) in variable.value"
                  :key="arrayIndex"
                >
                  <div class="columns my-2">
                    <div class="column is-10">
                      <BaseSelect
                        v-model="arrayVariable.type"
                        label="Property type"
                        variant="list"
                      >
                        <option
                          v-for="(type, i) in typesForArray"
                          :key="i"
                        >
                          {{ type }}
                        </option>
                      </BaseSelect>
                    </div>
                    <div class="column is-2 mt-5 delete-div">
                      <b-button @click="deleteItem(variableIndex, arrayIndex)">
                        <b-icon
                          icon="delete-outline"
                          type="is-danger"
                        />
                      </b-button>
                    </div>
                  </div>

                  <div
                    v-if="['String', 'Number', 'Boolean'].includes(arrayVariable.type)"
                  >
                    <editor
                      v-model="arrayVariable.value"
                      :formula="true"
                      :input="true"
                      label="Property value"
                    />
                  </div>

                  <div
                    v-if="arrayVariable.type === 'Object'"
                    class="nested"
                  >
                    <VariablesBuilderBlock
                      :variables="arrayVariable.children"
                      :are-children="true"
                    />
                  </div>
                </div>
                <b-button
                  size="is-small"
                  class="my-2"
                  expanded
                  @click="addItem(variableIndex)"
                >
                  Add item to the array
                </b-button>
              </div>
            </div>
          </template>
        </b-collapse>
      </Draggable>
    </section>

    <b-button
      size="is-small"
      expanded
      type="is-primary"
      @click="addProperty()"
    >
      Add variable
    </b-button>
  </div>
</template>

<script >
// libs
import { defineAsyncComponent, ref } from '@vue/composition-api';
import { uuid } from 'vue-uuid';
import Draggable from 'vuedraggable';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
// store
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useDataMapper } from './dataMapper';
import { useRoute } from '@/hooks/vueRouter';

// @TO-DO's: Find way to update props.variables in this recursive component without mutating prop 

const VariablesBuilderBlock = defineAsyncComponent(() => import('./VariablesBuilderBlock.vue'));
const __sfc_main = {};
__sfc_main.props = {
  variables: {
    type: Array,
    required: true
  },
  areChildren: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const moduleStore = useModuleStore();
  const {
    shouldAvoidVariablesUpdate,
    dataMapperInfo
  } = useDataMapper();
  const route = useRoute();
  const types = ref(['--select a type--', 'String', 'Number', 'Boolean', 'Object', 'Array']);
  const typesForArray = ref(['--select a type--', 'String', 'Number', 'Boolean', 'Object']);
  const addProperty = () => {
    // eslint-disable-next-line vue/no-mutating-props
    props.variables.push({
      name: '',
      type: '--select a type--',
      value: '',
      reference: uuid.v4(),
      children: []
    });
  };
  const addItem = index => {
    if (!Array.isArray(props.variables[index].value)) {
      // eslint-disable-next-line vue/no-mutating-props
      props.variables[index].value = [];
    }

    // eslint-disable-next-line vue/no-mutating-props
    props.variables[index].value.push({
      type: '--select a type--',
      value: '',
      children: []
    });
  };
  const deleteProperty = async index => {
    try {
      const {
        appId
      } = route.params;
      const {
        moduleId
      } = moduleStore;
      shouldAvoidVariablesUpdate.value = true;
      await moduleStore.deleteVariables(appId, moduleId, [props.variables[index].reference]);
      // eslint-disable-next-line vue/no-mutating-props
      props.variables.splice(index, 1);
    } catch (err) {
      console.error(err);
    }
  };
  const deleteItem = (index, arrayIndex) => {
    // eslint-disable-next-line vue/no-mutating-props
    props.variables[index].value.splice(arrayIndex, 1);
  };
  const sortVariables = async () => {
    // eslint-disable-next-line vue/no-mutating-props
    dataMapperInfo.value.fields.variables = props.variables;
  };
  return {
    types,
    typesForArray,
    addProperty,
    addItem,
    deleteProperty,
    deleteItem,
    sortVariables
  };
};
__sfc_main.components = Object.assign({
  Draggable,
  BaseInput,
  BaseSelect,
  Editor,
  VariablesBuilderBlock
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.variable-builder {
  padding: 1rem 0;
  border-bottom: 1px solid #4943FC;

  .trigger-container {
    display: grid;
    grid-template-columns: 1fr auto auto;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    max-height: 2.7rem;
    overflow: hidden;
  }

  .delete-div button {
    width: 30px;
    height: 30px;
    background: rgba(251, 131, 117, 0.2);
    border-radius: 8px;
    border-color: transparent;
  }

  .delete-div button:hover {
    border-color: transparent;
  }

  .nested {
    border: 1px solid #15C4BA;
    padding: 15px;
    margin-bottom: 20px;
  }
}
</style>