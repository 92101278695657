var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"n-grid",class:{
    'no-v-border': !_vm.field.properties.basic.hasBorder.vertical,
    'no-h-border': !_vm.field.properties.basic.hasBorder.horizontal,
    'no-border': !_vm.field.properties.basic.hasBorder.all,
  }},[_c('tr',{staticClass:"column-row"},_vm._l((_vm.field.properties.basic.columns.limit),function(_,colIndex){return _c('th',{key:'grid-column' + colIndex,style:({
        width: _vm.field.properties.basic.columns.width[colIndex] ? ((_vm.field.properties.basic.columns.width[colIndex]) + "%") : 'auto' 
      })})}),0),_vm._l((_vm.field.properties.basic.cells.data),function(row,rIndex){return _c('tr',{key:'grid-row' + rIndex},_vm._l((row),function(cell,cIndex){return _c('td',{key:'grid-cell' + rIndex + cIndex,staticClass:"grid-cell",class:{
        selected: _vm.selectedGridCells.length && rIndex === _vm.selectedGridCells[0].rowIndex && cIndex === _vm.selectedGridCells[0].columnIndex,
        'no-border-left': !cell.properties.basic.border.left,
        'no-border-right': !cell.properties.basic.border.right,
        'no-border-top': !cell.properties.basic.border.top,
        'no-border-bottom': !cell.properties.basic.border.bottom
      },on:{"click":function($event){return _vm.openCellProperties(cell, rIndex, cIndex)}}},[(cell.properties.basic.element)?_c('FieldRenderer',{key:'grid-cell' + rIndex + cIndex,attrs:{"field":cell.properties.basic.element}}):_vm._e()],1)}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }