<template>
  <table 
    class="n-grid"
    :class="{
      'no-v-border': !field.properties.basic.hasBorder.vertical,
      'no-h-border': !field.properties.basic.hasBorder.horizontal,
      'no-border': !field.properties.basic.hasBorder.all,
    }"
  >
    <tr class="column-row">
      <th
        v-for="(_, colIndex) in field.properties.basic.columns.limit"
        :key="'grid-column' + colIndex"
        :style="{
          width: field.properties.basic.columns.width[colIndex] ? `${field.properties.basic.columns.width[colIndex]}%` : 'auto' 
        }"
      />
    </tr>
    <tr
      v-for="(row, rIndex) in field.properties.basic.cells.data"
      :key="'grid-row' + rIndex"
    >
      <td
        v-for="(cell, cIndex) in row"
        :key="'grid-cell' + rIndex + cIndex"
        class="grid-cell"
        :class="{
          selected: selectedGridCells.length && rIndex === selectedGridCells[0].rowIndex && cIndex === selectedGridCells[0].columnIndex,
          'no-border-left': !cell.properties.basic.border.left,
          'no-border-right': !cell.properties.basic.border.right,
          'no-border-top': !cell.properties.basic.border.top,
          'no-border-bottom': !cell.properties.basic.border.bottom
        }"
        @click="openCellProperties(cell, rIndex, cIndex)"
      >
        <FieldRenderer
          v-if="cell.properties.basic.element"
          :key="'grid-cell' + rIndex + cIndex"
          :field="cell.properties.basic.element"
        />
      </td>
    </tr>
  </table>
</template>

<script >
import { defineAsyncComponent } from '@vue/composition-api';
import { useGrid } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/components/grid/grid';
const FieldRenderer = defineAsyncComponent(() => import('./FieldRenderer.vue'));
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    selectedGridCells,
    selectGridCells
  } = useGrid();
  const openCellProperties = (data, rowIndex, columnIndex) => {
    selectGridCells(data, rowIndex, columnIndex);
  };
  return {
    selectedGridCells,
    openCellProperties
  };
};
__sfc_main.components = Object.assign({
  FieldRenderer
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.n-grid {
  &.no-v-border {
    tr {
      >th, >td {
        &:not(&:first-child), &:not(&:last-child) {
          border-left: none;
          border-right: none;
        }
      }
    }
  }
  &.no-h-border {
    tr {
      >th, >td {
        &:not(&:first-child), &:not(&:last-child) {
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }
  &.no-border {
    tr {
      th, td {
        border: none;
      }
    }
  }
  width: 100%;
  table-layout: fixed;
  .column-row {
    visibility: collapse;
  }
  td {
    border: 1px solid;
    &.no-border {
      &-left {
        border-left: none !important;
      }
      &-right {
        border-right: none !important;
      }
      &-top {
        border-top: none !important;
      }
      &-bottom {
        border-bottom: none !important;
      }
    }
  }
  .grid-cell {
    transition: background 0.2s ease-in-out;
    padding: 0.25rem 0.5rem;
    &.selected, &:hover {
      background: rgba($grey-6, 0.5);
    }
    &:empty{
          padding: 1rem 2rem;
    }
  }
}
</style>
