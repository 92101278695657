import { computed, ref } from '@vue/composition-api';

export const actionTypes = {
    API_BUILDER: 'Build an API',
    DOCUMENT_CREATOR: 'Create Document',
    DATA_MAPPER: 'Map/Pair Data',
    REMOTE_DATABASE_ACTIONS: 'Read/Write To Remote Database',
    API_CONNECTOR: 'Send API Request',
    EMAIL_SENDER: 'Send Email',
    LOCAL_DATABASE_ACTIONS: 'Local Database Actions',
    FILE_READER: 'Read a file',
    FILE_MOVER: 'Move a file',
    FILE_UPLOADER: 'Upload File',
    DOCUMENT_MERGER: 'Merge Documents',
    REDIRECT: 'Redirect',
    VARIABLE_SETTER: 'Set Variable'
};

const actionsList = [
    {
        type: actionTypes.API_BUILDER,
        description: 'Description...',
        icon: 'api',
    },
    {
        type: actionTypes.DOCUMENT_CREATOR,
        description: 'Description...',
        icon: 'file-document-edit-outline',
    },
    {
        type: actionTypes.DATA_MAPPER,
        description: 'Description...',
        icon: 'code-json',
    },
    {
        type: actionTypes.REMOTE_DATABASE_ACTIONS,
        description: 'Description...',
        icon: 'database-export',
    },
    {
        type: actionTypes.API_CONNECTOR,
        description: 'Description...',
        icon: 'send-check',
    },
    {
        type: actionTypes.EMAIL_SENDER,
        description: 'Description...',
        icon: 'email-send',
    },
    {
        type: actionTypes.LOCAL_DATABASE_ACTIONS,
        description: 'Description...',
        icon: 'database',
    },
    {
        type: actionTypes.FILE_READER,
        description: 'Description...',
        icon: 'file-eye',
    },
    {
        type: actionTypes.FILE_MOVER,
        description: 'Description...',
        icon: 'file-swap',
    },
    {
        type: actionTypes.FILE_UPLOADER,
        description: 'Description...',
        icon: 'file-upload',
    },
    {
        type: actionTypes.DOCUMENT_MERGER,
        description: 'Description...',
        icon: 'text-box-multiple',
    },
    {
        type: actionTypes.REDIRECT,
        description: 'Description...',
        icon: 'arrow-right',
    },
    {
        type: actionTypes.VARIABLE_SETTER,
        description: 'Description...',
        icon: 'variable',
    }
];

const allowedActionTypes = ref([]);

const actionsConfig = ref({
    [actionTypes.API_CONNECTOR]: {
        showResponseSection: true
    }
});

export const useActionSidebar = () => {

    const allowedActionsList = computed(() => allowedActionTypes.value?.length ? actionsList.filter(action => allowedActionTypes.value.includes(action.type)): actionsList);
    
    return {
        allowedActionsList,
        allowedActionTypes,
        actionsConfig,
        actionsList
    };
};
