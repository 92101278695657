<template>
  <Node 
    :node-id="nodeId"
    icon="file-code-outline"
    shape="right-pointer"
    class="integration-node"
  />
</template>

<script >
import Node from './Node.vue';

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  nodeId: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  Node
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.integration-node {
  .node-icon {
    background: $danger;
  }
}
</style>