import { createPubSub } from '@/helpers/pubSub';

/** event bus (utilizing pub-sub pattern) for module components */
export const moduleEventBus = createPubSub();

// event names
export const ON_CHANGE_SIDEBAR_CONTENT = 'onChangeSidebarContent'; 
export const ON_END = 'onEnd'; 
export const ON_CHANGE_SIDEBAR_CONTENT_CONNECTION = 'onChangeSidebarContentConnection';
export const ON_NODE_REMOVED = 'onNodeRemoved';
