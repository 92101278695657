<template>
  <BaseModal
    v-model="isWorkflowListModalActive"
    :has-modal-card="true"
    :trap-focus="true"
    :full-screen="true"
    custom-class="workflow-modal"
    aria-role="dialog"
    aria-label="workflows"
    aria-modal
  >
    <CardPopup
      class="w-full"
      :show-footer="false"
    >
      <template #header>
        <p class="modal-card-title">
          Workflows
        </p>
        <div class="is-flex is-align-items-center">
          <b-button
            size="is-small"
            class="px-5 rounded-8 btn-primary-light mr-3"
            icon-left="plus"
            @click="setCreateWorkflowModalVisibility(true)"
          >
            Add New
          </b-button>
          <button
            class="delete"
            aria-label="close"
            @click="setWorkflowListModalVisibility(false)"
          />
        </div>
      </template>
      <template #body>
        <WorkflowList
          @create="setCreateWorkflowModalVisibility(true)"
          @edit="openWorkflowBuilder"
          @close-list-modal="setWorkflowListModalVisibility(false)"
        />
        <CreateWorkflowModal
          v-model="isCreateWorkflowModal"
          @create="createWorkflow"
        />
      </template>
    </CardPopup>
  </BaseModal>
</template>
  
<script >
// libs
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
// components
import WorkflowList from './WorkflowList.vue';
import CreateWorkflowModal from './CreateWorkflowModal.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useWorkflowStore } from '@/modules/builder/store/workflowStore';
// composables
import { useRoute, useRouter } from '@/hooks/vueRouter';
// services
import { createVariableService } from '@/services/application-service/variableRequests';
// misc
import { generateNode } from '@/modules/core/components/generics/base-tree-flow/treeFlowUtils';
import { WORKFLOW_TYPE_ID } from '@/constants/moduleTypes';
import { WORKFLOW_EDITOR_ROUTE_NAME } from '@/router/routeConstants';

//-- lazy loaded components --//
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  appName: {
    type: String,
    default: ''
  },
  value: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- component events --//
  const emit = __ctx.emit;
  const route = useRoute();
  const router = useRouter();
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const workflowStore = useWorkflowStore();
  const isWorkflowListModalActive = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  const isCreateWorkflowModal = ref(false);
  const setCreateWorkflowModalVisibility = visibility => {
    isCreateWorkflowModal.value = visibility;
  };
  const setWorkflowListModalVisibility = visibility => {
    isWorkflowListModalActive.value = visibility;
  };

  /**
   * 
   * @param {import('@/types/workflowTypes').IWorkflowInfo} newWorkflowInfo 
   */
  const createWorkflow = async newWorkflowInfo => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      const nodeData = {
        type: 'start',
        data: {
          name: 'Start'
        }
      };
      const newNode = generateNode([], nodeData);
      const {
        name,
        ...restWorkflowInfo
      } = newWorkflowInfo;
      const workflowId = await moduleStore.createModule(appId, {
        name,
        workflow: {
          ...restWorkflowInfo,
          moduleId
        },
        type_id: WORKFLOW_TYPE_ID,
        data: [newNode]
      });
      const newNodeInfo = {
        type: 'Always',
        name: 'Start'
      };
      await moduleGraphStore.addNodeInfo(appId, moduleId, newNode.nodeId, newNodeInfo);
      await createVariableService(appId, workflowId, {
        module_id: workflowId,
        node_id: newNode.nodeId,
        node_name: newNodeInfo.name,
        variables: [{
          name: 'Params',
          reference: `${newNode.nodeId}_params`
        }]
      });
      await workflowStore.fetchWorkflows(appId, moduleId);
      openWorkflowBuilder(workflowId);
    } catch (err) {
      console.error(err);
    }
  };
  const openWorkflowBuilder = workflowId => {
    const {
      appId,
      moduleId
    } = route.params;
    // open workflow builder page in new tab
    const workflowEditorRoute = router.resolve({
      name: WORKFLOW_EDITOR_ROUTE_NAME,
      params: {
        appId,
        moduleId,
        workflowId
      }
    });
    window.open(workflowEditorRoute.href, '_blank');
  };
  return {
    isWorkflowListModalActive,
    isCreateWorkflowModal,
    setCreateWorkflowModalVisibility,
    setWorkflowListModalVisibility,
    createWorkflow,
    openWorkflowBuilder
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  WorkflowList,
  CreateWorkflowModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
</style>
