<template>
  <div
    class="mt-2"
  >
    <BaseLabel>Choices</BaseLabel>
    <div
      v-for="(choice, index) in basicProperties.choices"
      :key="index"
      class="columns my-0 py-0"
    >
      <div class="column is-10 pb-1 pt-0">
        <BaseInput
          v-model="basicProperties.choices[index]"
          expanded
        />
      </div>
      <div class="column is-2 pb-1 pt-0">
        <b-button
          expanded
          class="is-danger"
          @click="deleteChoice(index)"
        >
          <b-icon
            size="is-small"
            icon="trash-can"
          />
        </b-button>
      </div>
    </div>
    <b-button
      type="is-primary"
      expanded
      @click="addChoice"
    >
      Add choice
    </b-button>
  </div>
</template>

<script >
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const {
    selectedFields
  } = useFormBuilder();
  const deleteChoice = index => {
    if (selectedFields.value?.length) {
      basicProperties.value.choices.splice(index, 1);
    }
  };
  const addChoice = () => {
    basicProperties.value.choices.push('New choice');
  };
  return {
    basicProperties,
    deleteChoice,
    addChoice
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
