<template>
  <div class="mt-2 mb-2">
    <DynamicValueSwitch
      v-model="basicProperties.hasState"
      :is-dynamic="basicProperties.hasStateDynamic"
      label="Include State"
      @update-dynamic-switch="updateBasicProperty('hasStateDynamic', $event)"
    />
    <DynamicValueSwitch
      v-model="basicProperties.hasDistrict"
      :is-dynamic="basicProperties.hasDistrictDynamic"
      class="mt-2"
      label="Include District"
      @update-dynamic-switch="updateBasicProperty('hasDistrictDynamic', $event)"
    />
    <DynamicValueSwitch
      v-model="basicProperties.hasCountry"
      :is-dynamic="basicProperties.hasCountryDynamic"
      class="mt-2"
      label="Include Country"
      @update-dynamic-switch="updateBasicProperty('hasCountryDynamic', $event)"
    />
    <div
      v-if="basicProperties.labels"
      class="mb-2 mt-2"
    >
      <BaseLabel variant="bold">
        Labels:
      </BaseLabel>
      <div class="columns is-multiline p-0 mt-3">
        <BaseInput
          v-model="basicProperties.labels.street"
          label="Label for street"
          expanded
          class="column is-6 py-1"
        />
        <BaseInput
          v-model="basicProperties.labels.number"
          label="Label for street number"
          expanded
          class="column is-6 py-1"
        />
        <BaseInput
          v-model="basicProperties.labels.zipCode"
          label="Label for zip code"
          expanded
          class="column is-6 py-1"
        />
        <BaseInput
          v-model="basicProperties.labels.city"
          label="Label for city"
          expanded
          class="column is-6 py-1"
        />
        <BaseInput
          v-if="basicProperties.hasState || basicProperties.hasStateDynamic"
          v-model="basicProperties.labels.state"
          label="Label for state"
          expanded
          class="column is-6 py-1"
        />
        <BaseInput
          v-if="basicProperties.hasDistrict || basicProperties.hasDistrictDynamic"
          v-model="basicProperties.labels.district"
          label="Label for district"
          expanded
          class="column is-6 py-1"
        />
        <BaseInput
          v-if="basicProperties.hasCountry || basicProperties.hasCountryDynamic"
          v-model="basicProperties.labels.country"
          label="Label for country"
          expanded
          class="column is-6 py-1"
        />
      </div>
    </div>
  </div>
</template>

<script >
import { set } from '@vue/composition-api';
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const updateBasicProperty = (key, value) => {
    if (key in basicProperties.value) {
      basicProperties.value[key] = value;
    } else {
      set(basicProperties.value, key, value);
    }
  };
  return {
    basicProperties,
    updateBasicProperty
  };
};
__sfc_main.components = Object.assign({
  DynamicValueSwitch,
  BaseLabel,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>