<template>
  <div
    ref="treeConnectionsElement"
    class="tree-connections"
  >
    <Connection
      v-for="(connection, cIndex) in treeConnections"
      :key="cIndex"
      :line-coordinates="connection.coordinates"
      :connection-style="connection.style"
      :connection-condition="connection.condition"
      :connection-id="connection.connectionId"
      :delete-connection-button="deleteConnectionButton"
      :connection-label-component="connectionLabelComponent"
      :is-connection-selected="selectedConnectionId === connection.connectionId"
      :connection-direction="connection.connectionDirectionFromSource"
    />
  </div>
</template>

<script >
import Connection from './Connection.vue';
import { ref } from '@vue/composition-api';
import { delay, nextFrame } from '@/helpers/util';
import { getRelativeBoundingRect, isChildNode } from './treeFlowInternal';
import { ON_CONNECTIONS_UPDATE, subscription } from './treeFlowPubSub';
import { useTreeConnections } from './treeConnections';

//-- component props --//
const __sfc_main = {};
__sfc_main.props = {
  treeNodes: {
    type: Array,
    required: true,
    description: 'List of nodes in tree'
  },
  deleteConnectionButton: {
    type: Object,
    default: null,
    description: 'custom component for delete connection button'
  },
  selectedConnectionId: {
    type: String,
    default: '',
    description: 'Id of selected connection (adds selected css class to connection with specified ID)'
  },
  connectionLabelComponent: {
    type: Object,
    default: null,
    description: 'Custom component for condition label on each connection'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- compose hooks --//
  const {
    computeConnectionOffsets,
    getNodeElementBoundingRect,
    getConnectionLineOffset,
    getConnectionDirectionFromSource
  } = useTreeConnections();

  /** @type {import('@vue/composition-api').Ref<import('./types/treeConnections').ITreeConnection[]>} */
  const treeConnections = ref([]);
  /** @type {import('@vue/composition-api').Ref<HTMLElement | null>} */
  const treeConnectionsElement = ref(null);

  //-- connection update logic --//
  const handleConnectionsUpdate = async () => {
    await nextFrame(); // wait for browser to apply changes in DOM
    // map all tree connections
    if (props.treeNodes.length) {
      computeConnectionOffsets(props.treeNodes);
      await delay(500);
      treeConnections.value = props.treeNodes.filter(node => node.connections.length).flatMap(node => node.connections.map(connection => generateConnection(node.nodeId, connection)));
    }
  };
  subscription.subscribe(ON_CONNECTIONS_UPDATE, handleConnectionsUpdate);

  /**
   * Creates connection object as required by Connection component
   * @param {string} nodeId 
   * @param {import('./types/tree').IConnection} connection
   */
  const generateConnection = (nodeId, connection) => {
    const sourceNodeBoundingRect = getNodeElementBoundingRect(nodeId);
    const targetNodeBoundingRect = getNodeElementBoundingRect(connection.targetNodeId);

    // check if targetNode is child of node as it require different positioning computations
    const targetNode = props.treeNodes.find(node => node.nodeId === connection.targetNodeId);
    const isChild = isChildNode(nodeId, targetNode);
    const style = {
      position: 'absolute',
      ...getConnectionPosition(sourceNodeBoundingRect, targetNodeBoundingRect, isChild)
    };
    const sourceTargetDeltaX = sourceNodeBoundingRect.x - targetNodeBoundingRect.x;
    const connectionDirectionFromSource = getConnectionDirectionFromSource(sourceTargetDeltaX);
    const coordinates = getConnectionCoordinates(connection, sourceNodeBoundingRect, targetNodeBoundingRect, isChild, connectionDirectionFromSource);
    return {
      nodeId,
      connectionId: connection.id,
      coordinates,
      condition: connection.condition,
      style,
      connectionDirectionFromSource
    };
  };

  /**
   * @param {DOMRect} sourceNodeBoundingRect
   * @param {DOMRect} targetNodeBoundingRect
   * @param {boolean} isChildNode
   */
  const getConnectionPosition = (sourceNodeBoundingRect, targetNodeBoundingRect, isChildNode) => {
    /** @type {CSSStyleDeclaration} */
    const connectionPosition = {};
    const {
      x: sourceX,
      right: sourceRight
    } = getRelativeBoundingRect(sourceNodeBoundingRect, treeConnectionsElement.value);
    const {
      x: targetX,
      right: targetRight
    } = getRelativeBoundingRect(targetNodeBoundingRect, treeConnectionsElement.value);
    if (isChildNode) {
      connectionPosition.left = '0';
    } else {
      /** Total distance along X axis towards left side of tree */
      const leftXDistance = sourceX + targetX;
      /** Total distance along X axis towards right side of tree */
      const rightXDistance = 2 * treeConnectionsElement.value.clientWidth - (targetRight + sourceRight);

      // position svg according to whichever is minimum between these distances
      if (rightXDistance < leftXDistance) {
        // connection placed at right side of tree
        connectionPosition.right = '0';
      } else {
        // connection placed at left side of tree
        connectionPosition.left = '0';
      }
    }
    return connectionPosition;
  };

  /**
   * @param {import('./types/tree').IConnection} connection
   * @param {DOMRect} sourceNodeBoundingRect
   * @param {DOMRect} targetNodeBoundingRect
   * @param {boolean} isChildNode
   * @param {string} connectionDirectionFromSource
   */
  const getConnectionCoordinates = (connection, sourceNodeBoundingRect, targetNodeBoundingRect, isChildNode, connectionDirectionFromSource) => {
    // get offsets relative to root container element of tree (as tree is draggable and when it is dragged, offsets relative to viewport will produce incorrect results)
    const {
      x: sourceX,
      y: sourceY,
      width: sourceWidth,
      height: sourceHeight
    } = getRelativeBoundingRect(sourceNodeBoundingRect, treeConnectionsElement.value);
    const {
      x: targetX,
      y: targetY,
      width: targetWidth,
      height: targetHeight
    } = getRelativeBoundingRect(targetNodeBoundingRect, treeConnectionsElement.value);
    if (isChildNode) {
      const lineOffset1 = getConnectionLineOffset(connection.id, 1);
      const lineOffset2 = getConnectionLineOffset(connection.id, 2);
      const lineOffset3 = getConnectionLineOffset(connection.id, 3);
      return [{
        // path direction: ↓
        x1: sourceX + sourceWidth / 2,
        y1: sourceY + sourceHeight,
        x2: sourceX + sourceWidth / 2,
        y2: sourceY + sourceHeight + 15 + lineOffset1.yOffset
      }, {
        // path direction: ← or →
        x1: sourceX + sourceWidth / 2,
        y1: sourceY + sourceHeight + 15 + lineOffset2.yOffset,
        x2: targetX + targetWidth / 2,
        y2: sourceY + sourceHeight + 15 + lineOffset2.yOffset,
        ...(connectionDirectionFromSource !== 'straight' ? {
          'data-has-label': true
        } : {})
      }, {
        // path direction: ↓
        x1: targetX + targetWidth / 2,
        y1: sourceY + sourceHeight + 15 + lineOffset3.yOffset,
        x2: targetX + targetWidth / 2,
        y2: targetY,
        ...(connectionDirectionFromSource === 'straight' ? {
          'data-has-label': true
        } : {})
      }];
    } else {
      // coordinates for route back connections
      const lineOffset1 = getConnectionLineOffset(connection.id, 1);
      const lineOffset2 = getConnectionLineOffset(connection.id, 2);
      const lineOffset3 = getConnectionLineOffset(connection.id, 3);
      const lineOffset4 = getConnectionLineOffset(connection.id, 4);
      const lineOffset5 = getConnectionLineOffset(connection.id, 5);
      if (connectionDirectionFromSource === 'right') {
        // coordinates for connection going through right side
        return [{
          // path direction: ↓
          x1: sourceX + sourceWidth / 2,
          y1: sourceY + sourceHeight,
          x2: sourceX + sourceWidth / 2,
          y2: sourceY + sourceHeight + 15 + lineOffset1.yOffset
        }, {
          // path direction: →
          x1: sourceX + sourceWidth / 2,
          y1: sourceY + sourceHeight + 15 + lineOffset2.yOffset,
          x2: sourceX + sourceWidth + lineOffset2.xOffset,
          y2: sourceY + sourceHeight + 15 + lineOffset2.yOffset,
          'data-has-label': true // Connection component reads this data attribute to add label on connection
        }, {
          // path direction: ↑
          x1: sourceX + sourceWidth + lineOffset3.xOffset,
          y1: sourceY + sourceHeight + 15 + lineOffset3.yOffset,
          x2: sourceX + sourceWidth + lineOffset3.xOffset,
          y2: targetY - lineOffset4.yOffset
        }, {
          // path direction: →
          x1: sourceX + sourceWidth + lineOffset3.xOffset,
          y1: targetY - lineOffset5.yOffset,
          x2: targetX + lineOffset5.xOffset,
          y2: targetY - lineOffset5.yOffset
        }, {
          // path direction: ↓
          x1: targetX + lineOffset5.xOffset,
          y1: targetY - lineOffset5.yOffset,
          x2: targetX + lineOffset5.xOffset,
          y2: targetY
        }];
      } else {
        // coordinates for left side
        return [{
          // path direction: ↓
          x1: sourceX + sourceWidth / 2,
          y1: sourceY + sourceHeight,
          x2: sourceX + sourceWidth / 2,
          y2: sourceY + sourceHeight + 15 + lineOffset1.yOffset
        }, {
          // path direction: ←
          x1: sourceX + sourceWidth / 2,
          y1: sourceY + sourceHeight + 15 + lineOffset2.yOffset,
          x2: sourceX - lineOffset2.xOffset,
          y2: sourceY + sourceHeight + 15 + lineOffset2.yOffset,
          'data-has-label': true
        }, {
          // path direction: ↑
          x1: sourceX - lineOffset2.xOffset,
          y1: sourceY + sourceHeight + 15 + lineOffset3.yOffset,
          x2: sourceX - lineOffset2.xOffset,
          y2: targetY - lineOffset4.yOffset
        }, {
          // path direction: ←
          x1: sourceX - lineOffset2.xOffset,
          y1: targetY - lineOffset4.yOffset,
          x2: targetX + targetWidth - lineOffset5.xOffset,
          y2: targetY - lineOffset4.yOffset
        }, {
          // path direction: ↓
          x1: targetX + targetWidth - lineOffset5.xOffset,
          y1: targetY - lineOffset4.yOffset,
          x2: targetX + targetWidth - lineOffset5.xOffset,
          y2: targetY
        }];
      }
    }
  };
  return {
    treeConnections,
    treeConnectionsElement
  };
};
__sfc_main.components = Object.assign({
  Connection
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.tree-connections {
  position: relative;
  width: 100%;
  height: 1px;
  pointer-events: none;
  .node-connection {
    width: 100%;
    height: 100vh; // keep height relative to viewport so that svg connections can scale with zoom
  }
}
</style>
