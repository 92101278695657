<template>
  <div
    class="columns tariff w-full"
  >
    <div
      v-for="(tariff, tIndex) in field.properties.basic.tariffs"
      :key="'tariff' + tIndex"
      class="column tariff"
      :class="'is-' + (12/field.properties.basic.tariffs.length)"
    >
      {{ 'Tariff ' + (tIndex + 1) }}
    </div>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.tariff {
  border: 1px solid $grey-2;
  height: 300px;
}
</style>
