<template>
  <div class="payments-gateway" />
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.payments-gateway {
    text-align: center;
    width: 100%;
    height: 5rem;
    border: 1px solid $grey-2;
    padding: 0.5rem;
}
</style>
