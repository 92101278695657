<template>
  <div v-if="basicProperties && Object.keys(basicProperties).length">
    <BaseInput
      :value="basicProperties.columns ? basicProperties.columns.limit : ''"
      label="Columns"
      type="number"
      expanded
      :max="maxColumnLimit"
      @input="updateGridSize($event, 'column')"
    />
    <BaseInput
      :value="basicProperties.rows ? basicProperties.rows.limit : ''"
      label="Rows"
      type="number"
      expanded
      @input="updateGridSize($event, 'row')"
    />
    <div
      class="mb-2"
    >
      <BaseLabel class="is-block">
        Grid Borders:
      </BaseLabel>
      <b-switch
        v-model="basicProperties.hasBorder.vertical"
      >
        Vertical
      </b-switch>
      <b-switch
        v-model="basicProperties.hasBorder.horizontal"
      >
        Horizontal
      </b-switch>
      <b-switch
        v-model="basicProperties.hasBorder.all"
      >
        All
      </b-switch>
    </div>
    <template v-if="selectedGridCells.length">
      <strong>
        Cell properties:
      </strong>
      <BaseInput
        v-if="basicProperties.columns"
        v-model="basicProperties.columns.names[selectedGridCells[0].columnIndex]"
        label="Column Name"
        expanded
      />
      <BaseInput
        v-if="basicProperties.rows"
        v-model="basicProperties.rows.names[selectedGridCells[0].rowIndex]"
        label="Row Name"
        expanded
      />
      <BaseInput
        v-if="basicProperties.columns"
        v-model="basicProperties.columns.width[selectedGridCells[0].columnIndex]"
        type="number"
        label="Column Width (in %)"
        expanded
      />
      <CellProperties />
    </template>
  </div>
</template>

<script >
// libs
import { ref } from '@vue/composition-api';
// components
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import CellProperties from './CellProperties.vue';
// Stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import { useGrid } from './grid';
import { useRoute } from '@/hooks/vueRouter';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
// others
import { generateGridCells, createGridCell } from '@/modules/builder/components/form-builder/form-elements/elementFactories';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    selectedGridCells
  } = useGrid();
  const {
    basicProperties
  } = useBasicProperties();
  const moduleStore = useModuleStore();
  const route = useRoute();
  const {
    selectedField
  } = useFormBuilder();
  const maxColumnLimit = ref(12);
  const updateGridSize = async (newSize, type) => {
    newSize = parseInt(newSize || '1');
    const existingGridCells = basicProperties.value.cells.data;
    if (type === 'row') {
      const updatedRowSize = newSize - basicProperties.value.rows.limit;
      const rowNames = basicProperties.value.rows.names;
      if (updatedRowSize > 0) {
        // handle adding new rows
        basicProperties.value.cells.data = existingGridCells.concat(generateGridCells(updatedRowSize, basicProperties.value.columns.limit));
        basicProperties.value.rows.names = rowNames.concat(Array.from({
          length: updatedRowSize
        }, (_, index) => `${index + rowNames.length}`));
      } else if (updatedRowSize < 0) {
        // handle deleting rows
        basicProperties.value.cells.data = existingGridCells.slice(0, newSize);
        basicProperties.value.rows.names = rowNames.slice(0, newSize);
        await deleteStaleVariables();
      }
      basicProperties.value.rows.limit = newSize;
    } else if (type === 'column') {
      if (newSize > maxColumnLimit.value) {
        // avoid updating grid for excess columns
        return;
      }
      const updatedColumnSize = newSize - basicProperties.value.columns.limit;
      const {
        names: columnNames,
        width: columnWidth
      } = basicProperties.value.columns;
      if (updatedColumnSize > 0) {
        // handle adding new columns
        basicProperties.value.cells.data = existingGridCells.map(row => {
          row = row.concat(Array.from({
            length: updatedColumnSize
          }, createGridCell));
          return row;
        });
        basicProperties.value.columns.names = columnNames.concat(Array.from({
          length: updatedColumnSize
        }, (_, index) => `${String.fromCharCode(index + columnNames.length + 65)}`));
        basicProperties.value.columns.width = columnWidth.concat(Array.from({
          length: updatedColumnSize
        }, () => ''));
      } else if (updatedColumnSize < 0) {
        // handle deleting columns
        basicProperties.value.cells.data = existingGridCells.map(row => {
          row = row.slice(0, newSize);
          return row;
        });
        basicProperties.value.columns.names = columnNames.slice(0, newSize);
        basicProperties.value.columns.width = columnWidth.slice(0, newSize);
        await deleteStaleVariables();
      }
      basicProperties.value.columns.limit = newSize;
    }
  };
  const deleteStaleVariables = async () => {
    try {
      const cells = basicProperties.value.cells.data.flatMap(row => row.filter(cell => cell.properties.basic.element?.id));
      const gridVariables = moduleStore.variables.filter(variable => variable.reference.includes(selectedField.value.id));
      const staleVariables = gridVariables.filter(variable => !cells.some(cell => cell.properties.basic.element.id === variable.reference)).map(variable => variable.reference);
      const {
        appId
      } = route.params;
      const {
        moduleId
      } = moduleStore;
      await moduleStore.deleteVariables(appId, moduleId, staleVariables);
    } catch (err) {
      console.error(err);
    }
  };
  return {
    selectedGridCells,
    basicProperties,
    maxColumnLimit,
    updateGridSize
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseLabel,
  CellProperties
}, __sfc_main.components);
export default __sfc_main;
</script>
