<template>
  <div>
    <div
      v-for="(_, lIndex) in itemsLength"
      :key="'sidebar-item' + lIndex"
      class="mb-4"
    >
      <b-skeleton
        height="1.5rem"
        width="6rem"
      />
      <b-skeleton 
        height="2rem"
      />
    </div>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  itemsLength: {
    type: Number,
    default: 4
  }
};
export default __sfc_main;
</script>
