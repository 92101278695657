import { computed, ref } from '@vue/composition-api';
import { useRoute } from '@/hooks/vueRouter';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';

/** @type {import('@vue/composition-api').Ref<string[]>} */
const nodesCurrentlyLoading = ref([]);

export const useModuleSidebar = () => {
    const moduleGraphStore = useModuleGraphStore();
    const moduleStore = useModuleStore();
    const route = useRoute();


    const isSidebarLoading = computed(() => nodesCurrentlyLoading.value.includes(moduleGraphStore.selectedNodeId));

    const sidebarType = computed({
        get() {
            const nodeInfo = moduleGraphStore.selectedNode?.data;
            return nodeInfo?.type || '';
        },
        set(value) {
            moduleGraphStore.updateSelectedNodeData({
                type: value,
                name: ''
            });
        }
    });

    const addLoadingNode = (nodeId) => {
        if (!nodesCurrentlyLoading.value.includes(nodeId)) {
            nodesCurrentlyLoading.value.push(nodeId);
        }
    };

    const removeLoadingNode = (nodeId) => {
        nodesCurrentlyLoading.value = nodesCurrentlyLoading.value.filter(id => id !== nodeId);
    };

    const fetchSidebarInfo = async (nodeId) => {
        addLoadingNode(nodeId);
        const { appId } = route.params;
        await moduleGraphStore.getNodeInfoById(appId, moduleStore.moduleId, nodeId);
        removeLoadingNode(nodeId);
    };

    const saveSidebarInfo = async (nodeId, payload) => {
        const { appId } = route.params;
        if (payload.type && payload.name) {
            moduleGraphStore.updateSelectedNodeData(payload);
            const isNodeAvailable = !!moduleGraphStore.getNodeById(nodeId);
            if (isNodeAvailable) {
                await moduleGraphStore.updateNodeInfo(appId, moduleStore.moduleId, nodeId, payload);
            } else {
                await moduleGraphStore.addNodeInfo(appId, moduleStore.moduleId, nodeId, payload);
            }
        }
    };
    return {
        isSidebarLoading,
        nodesCurrentlyLoading,
        sidebarType,
        addLoadingNode,
        removeLoadingNode,
        fetchSidebarInfo,
        saveSidebarInfo
    };
};
