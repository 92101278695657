<template>
  <div>
    <BaseSelect
      label="Language type"
    >
      <option
        v-for="(lang, index) in options.xml.languages"
        :key="index"
      >
        {{ lang }}
      </option>
    </BaseSelect>

    <BaseLabel variant="bold">
      Header
    </BaseLabel>
    <div
      class="columns"
      style="margin-bottom: 0px;"
    >
      <div class="column is-5">
        Tag Name
      </div>
      <div class="column is-5">
        Tag Value
      </div>
      <div class="column is-2" />
    </div>
    <div
      v-for="(header, index) in dataMapperInfo.fields.xml.header"
      :key="index"
      class="columns"
    >
      <BaseInput
        v-model="header.name"
        class="column is-5"
        type="text"
        variant="list"
      />
      <Editor
        v-model="header.value"
        :input="true"
        :formula="true"
        class="column is-5"
      />
      <div class="column is-2 sidebar-del-row">
        <b-button @click="deleteFieldHeader(index)">
          <b-icon
            icon="delete-outline"
            type="is-danger"
          />
        </b-button>
      </div>
    </div>
    <div class="mb-4">
      <b-button
        type="is-primary"
        class="sidebar-add"
        @click="addFieldHeader"
      >
        <b-icon
          icon="plus"
          size="is-small"
          variant="light"
        />
        Add new header tag
      </b-button>
    </div>

    <BaseLabel variant="bold">
      Body
    </BaseLabel>
    <div
      v-for="(body, index) in dataMapperInfo.fields.xml.body"
      :key="index"
      class="columns"
    >
      <BaseInput
        v-model="body.name"
        class="column is-5"
        type="text"
        variant="list"
      />
      <Editor
        v-model="body.value"
        :input="true"
        :formula="true"
        class="column is-5"
      />
      <div class="column is-2 sidebar-del-row">
        <b-button @click="deleteFieldBody(index)">
          <b-icon
            icon="delete-outline"
            type="is-danger"
          />
        </b-button>
      </div>
    </div>
    <div class="mb-4">
      <b-button
        type="is-primary"
        class="sidebar-add"
        @click="addFieldBody"
      >
        <b-icon
          icon="plus"
          size="is-small"
          variant="light"
        />
        Add new body tag
      </b-button>
    </div>
  </div>
</template>

<script >
// libs
import { uuid } from 'vue-uuid';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
// composables
import { useDataMapper } from './dataMapper';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    dataMapperInfo
  } = useDataMapper();
  const addFieldHeader = () => {
    dataMapperInfo.value.fields.xml.header.push({
      namespace: '',
      name: '',
      value: '',
      reference: uuid.v4(),
      properties: []
    });
  };
  const deleteFieldHeader = index => {
    dataMapperInfo.value.fields.xml.header.splice(index, 1);
  };
  const addFieldPropertyHeader = index => {
    dataMapperInfo.value.fields.xml.header[index].properties.push({
      name: '',
      value: '',
      reference: uuid.v4()
    });
  };
  const addFieldBody = () => {
    dataMapperInfo.value.fields.xml.body.push({
      namespace: '',
      name: '',
      value: '',
      reference: uuid.v4(),
      properties: []
    });
  };
  const deleteFieldBody = index => {
    dataMapperInfo.value.fields.xml.body.splice(index, 1);
  };
  const addFieldPropertyBody = index => {
    dataMapperInfo.value.fields.xml.body[index].properties.push({
      name: '',
      value: ''
    });
  };
  return {
    dataMapperInfo,
    addFieldHeader,
    deleteFieldHeader,
    addFieldBody,
    deleteFieldBody
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  BaseLabel,
  BaseInput,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
