<template>
  <div
    style="width: 100%; border: 1px solid #dedede; height: 600px;"
  >
    Document Content
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
export default __sfc_main;
</script>
