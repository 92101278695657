<template>
  <ModuleBuilder
    v-if="moduleStore.moduleId" 
    class="h-full"
    :hidden-header-options="hiddenHeaderOptions"
  />
</template>

<script >
// lib
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
// components
import ModuleBuilder from '@/modules/builder/components/shared/module-builder/ModuleBuilder.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { nodeListDropdownTypes, useModuleGraph } from '@/modules/builder/components/module-graph/moduleGraph';
import { actionTypes, useActionSidebar } from '@/modules/builder/components/module-sidebar/action/actionSidebar';
// others
import { headerOptions } from '@/modules/builder/components/shared/module-builder/moduleBuilderConstants';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleStore = useModuleStore();
  const {
    allowedNodeListOptions
  } = useModuleGraph();
  const {
    actionsConfig,
    allowedActionTypes
  } = useActionSidebar();
  const hiddenHeaderOptions = ref([headerOptions.WORKFLOWS, headerOptions.PREVIEW]);
  onMounted(() => {
    moduleStore.moduleId = route.params.workflowId;
    moduleStore.moduleVariablesToRetrieve = [moduleStore.moduleId, route.params.moduleId];
    allowedNodeListOptions.value = [nodeListDropdownTypes.BACKGROUND_ACTION];
    allowedActionTypes.value = [actionTypes.API_CONNECTOR, actionTypes.DATA_MAPPER, actionTypes.REDIRECT, actionTypes.VARIABLE_SETTER];
    actionsConfig.value[actionTypes.API_CONNECTOR].showResponseSection = false;
  });
  onUnmounted(() => {
    moduleStore.moduleId = '';
  });
  return {
    moduleStore,
    hiddenHeaderOptions
  };
};
__sfc_main.components = Object.assign({
  ModuleBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>
